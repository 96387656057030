/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { RolesManagerService } from 'ng-role-based-access-control';
import {
  Job,
  CachedJob,
  JobQueryData,
  ArtistJobItem,
  JobAudit,
  BallCourt,
  ArtistsJobsResourcesCategoriesRanking,
  ArtistsJobsItemsPolygonpecification,
  JobsQueryData,
  JobsFilterOptions,
  ArtistsItemSpecUI,
  ArtistsJobsPrivateComments,
  ArtistsJobsSubscriber,
  ArtistJobTag,
  PredefinedJobTags,
  StickyType
} from './job';
import { Subject, Subscription, Observable } from 'rxjs';
import { User, UserRole } from '../auth/user';
import { AuthService } from '../auth/auth.service';
import { GraphqlService } from '../communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { ResourceDimensions, ResourceWarning, ResourceWarningType, ResourceDetails, ResourceWarningLevel, Resource, ArtistsResourcesFeedback, ImagesFileTypes, MediaTag, CompressionType, CombineImagesMode, ArtistsJobsResourcesGeometryAccuracy, ArtistsJobsResourcesColorComparison } from './resource';
import { DimensionsUnits } from '../shared/utils';
import {
  KeyValuePair,
  KeyValueAnyPair,
  JobsTypes,
  AdjustmentsMode,
  FormatsType,
  LIGHTNESS_THRESHOLD,
  AB_THRESHOLD,
  QaModes
} from '../shared/enums';
import { EnumsService } from '../shared/enums.service';
import { RestService } from '../communication/rest.service';
import { ResourceTypesService } from '../product/resource-types.service';
import { ResourceType, Product } from '../product/product';
import { PricingHelperService } from '../shared/pricing-helper.service';
import { Notification, NotificationType } from '../shared/notifications';
import { UtilsService } from '../shared/utils.service';
import { BroadcasterService } from 'ng-broadcaster';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadError } from './job-item/file-upload-error';
import { MapperService } from '../shared/mapper.service';
import { Category, ProductSubCategory, CategoryDefaults, AttachmentFileType } from '../categories/category';
import { CategoriesService } from '../categories/categories.service';
import { PixelsService } from 'app/shared/pixels.service';
import { AssetAdjustmentsService, AdjustmentsSourceChanges, GltfExportOptions, ResourceJsonParams, ResourceJson, AdjustmentsSourceChangesState, RenderingEngine, CLAMPING_MODE, ThreeTexture, THREE_LATEST_VERSION } from 'asset-adjustments';
import { DecimalPipe } from '@angular/common';
import { NativeResourceSet, RenderType, KeyValuePoly, PolyType } from 'app/offer/offers';
import { UploadWizardWrapComponent } from './upload-wizard-wrap/upload-wizard-wrap.component';
import { Software } from 'app/softwares/softwares';
import { SoftwaresService } from 'app/softwares/softwares.service';
import { ResumableUploadService } from 'app/shared/resumable-upload.service';
import { ResumableState, ResumableSubject } from 'app/shared/resumable';
import { RolesHelperService } from 'app/auth/roles-helper.service';
import { MetaOptions } from 'app/shared/seo';
import { SeoService } from 'app/shared/seo.service';
import { FixStage, PixelArtistsEvents } from 'app/shared/pixels';
import { ChatService } from 'app/ui-components/chat.service';
import { UploadWizardOptions, UploadWizardStep } from './upload-wizard/upload-wizard';
import { ArtistLibrary, MeshLibraryWrapper } from 'app/mesh-library/mesh-library';
import { BodyService } from 'app/shared/body.service';
import { MeshLibraryDialogComponent } from 'app/mesh-library/mesh-library-dialog/mesh-library-dialog.component';
import { MeshLibraryTeaserComponent } from 'app/mesh-library/mesh-library-teaser/mesh-library-teaser.component';
import { SafeResourceUrl } from '@angular/platform-browser';
import { SafeWindowPipe } from 'app/shared/safe-window.pipe';
import { OfferService } from 'app/offer/offer.service';
import { AssetAdjustmentsHelperService } from 'app/ui-components/asset-adjustments-helper.service';
import { ArtistsUsersPresetType, AdjustmentsPresetOptions, AdjustmentsData } from 'app/ui-components/asset-adjustments';
import { SurveyService } from 'app/survey/survey.service';
import { SurveyResponse } from 'app/survey/survey';
import { SpecificationsReminderDialogComponent } from './specifications-reminder-dialog/specifications-reminder-dialog.component';
import { EndpointsService } from 'app/communication/endpoints.service';
import { ResourcePolygonReduction, ResourcePolygonReductionActions } from './resource-polygon-reduction';
import { AssetAdjustmentsWrapComponent } from './asset-adjustments-wrap/asset-adjustments-wrap.component';
import { Group, GroupType } from 'app/auth/group';
import {EndPoints} from '../communication/endpoints';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { artists_pause_reasons } from './feedback';


@Injectable()
export class JobService {
  static MAX_VIEWER_FILE_SIZE_1 = 3.5;
  static MAX_VIEWER_FILE_SIZE_2 = 2;
  static MAX_BIN_SIZE = 1.2;
  static MAX_GLB_SIZE = 5;
  static MAX_MESHES = 12;
  static MAX_MS_UNCHECK = 600000; // 10 minutes
  static MAX_TRANSPARENTS = 1;
  static DIMENSIONS_TOLERANCE = 1.05; // 1.015;
  static PRICE_DELTA = 10;
  static KEEP_UV_SUB_CAT = [206, 1, 20];
  static MIN_GEOMETRY_ACCURACY_SCORE = 95;
  static ERRORS_MAX_FILE_SIZE_MB = 100;
  static DIFFUSE_MIN_THRESHOLD = 30;
  static DIFFUSE_MAX_THRESHOLD = 243;
  private _hasFullDimensions: boolean;
  public job: Job;
  public reAssign: boolean;
  private _currentArtistsjobitem: ArtistJobItem;
  public currentArtistsjobitemId: number;
  public currentArtistsResourceRank: ArtistsJobsResourcesCategoriesRanking;
  public requiredFormats: Array<number>;
  public allFormats: Array<ResourceType>;
  public allAvailableFormats: Array<ResourceType>;
  public flatGaugeFrom: Date;
  public flatGaugeTo: Date;
  public currentFormat: number;
  public imagesDownloadLink: string;
  public resValue: Subject<Array<Resource>>;
  public onJobChange: Subject<Job>;
  public onResourceUploaded: Subject<Resource>;
  public feedbacks: Array<ArtistsResourcesFeedback>;
  public allFeedbacks: Array<ArtistsResourcesFeedback>;
  public unresolvedFeedbacks: number;
  private _currentModelIndex: number;
  public resourceDetails: ResourceDetails;
  public preservedModelIndex: number;
  public originalResources: Array<Resource>;
  public currentResource: Resource;
  public warnings: Array<ResourceWarning>;
  public canReadyForReview: boolean;
  public formatsDictionary: { [id: number]: ResourceType; };
  public categoriesDictionary: { [id: number]: Category; };
  public subCategoriesDictionary: { [id: number]: ProductSubCategory; };
  public catName: string;
  public subCatName: string;
  public subCatId: number;
  public parentCat: Category;
  public auditLog: Array<JobAudit>;
  public ballCourt: Array<BallCourt>;
  public endDate: Date;
  public rankBonusByUser: number;
  public timeBonusByUser: number;
  public currentTotal: number;
  public timeBonusExpires: Date;
  public renderType: RenderType;
  public artistPercentage: number;
  public clientPercentage: number;
  public artistHours: number;
  public clientHours: number;
  public timerPause: boolean;
  public finalPrice: number;
  public canDownloadSourcefiles: boolean;
  public canDownloadResourceFiles: boolean;
  public manager: User;
  public qualitySupervisor: User;
  public feedbacker: User;
  public saveOnAutoAdjustScene: boolean;
  public onResourceAdjustScene: Subject<Resource>;
  public meshLibraries: Array<ArtistLibrary>;
  public isFirstVisit: boolean;
  public onFetchingManager: Array<Function>;
  public pauseReason: string;
  public jobImages: Array<NativeResourceSet>;
  public onJobRefresh: Subject<void>;
  private mlTeaserOpened: boolean;
  private _fetchingManager: boolean;
  private subs: Array<Subscription>;
  private adjustmentsSubs: Array<Subscription>;
  private onAdjustmentsInitSub: Subscription;
  private currentJob: CachedJob;
  private pixelOnce: boolean;
  private gotChat: boolean;
  private compressOnFix: boolean;
  private _viewerUrl: SafeResourceUrl;
  private priceChanged: boolean;
  private onLightsSummary: Array<Function>;
  private onViewerFullyLoaded: Array<Function>;
  private _normalizedDimensions: ResourceDimensions;
  private _isAdjustmentsOnDom: boolean;
  //   private _numOfMultipleUV: number;
  private cancelExportIfMUV: boolean;
  private overlapMeshesNames: Array<KeyValuePair>;
  private negativeScaleCount: number;
  private _artistGroups: Array<Group>;
  private onArtistGroupsFetch: Array<Function>;
  private _execWarningsWhenDone: boolean;
  private _isWarningsRunning: boolean;
  private _isArtistUnderNDA: boolean;
  private _pausedReasonsFetching: Array<Function>;
  // private exportFileSizeOnLoad: boolean;
  // private exportFileSize: boolean;
  // private exportFileSizeResolve: Function;
  public tickInterval: any;
  public adjustmentsMode: AdjustmentsMode;
  public dimUnitsDictionary: { [id: number]: KeyValuePair; };
  public NORMAL = AdjustmentsMode.NORMAL;
  public FULLSCREEN = AdjustmentsMode.FULLSCREEN;
  public UPLOAD = AdjustmentsMode.UPLOAD;
  public exportProgress: number;
  public softwaresDictionary: { [id: number]: Software; };
  public mediaTag: MediaTag;
  public IFRAME: MediaTag;
  public MODEL: MediaTag;
  public VIDEO: MediaTag;
  public IMAGE: MediaTag;
  public isSU: boolean;
  public isAdmin: boolean;
  public isQualitySupervisor: boolean;
  public isSFM: boolean;
  public isTechnicalReviewer: boolean;

  public isGod: boolean;
  public polyTypesDictionary: { [id: number]: KeyValuePoly };
  public polyShapeTypesDictionary: { [id: number]: KeyValueAnyPair };
  public jobTypeDesc: string;
  public targetResourceType: number;
  public _totalTexturesSize: number;
  public _totalUVSize: number;
  public oldTotalTexturesSize: number;
  public blenderGlbFileSize: number;
  public artistsItemsSpecs: Array<ArtistsItemSpecUI>;
  public productsToAdd: Array<number>;
  public jobTags: Array<ArtistJobTag>;
  public jobPredefinedTags: Array<ArtistJobTag>;
  public canApprove: boolean;
  public glTF: FormatsType;
  public GLB: FormatsType;
  public FBX: FormatsType;
  public OBJ: FormatsType;
  public USDZ: FormatsType;
  public DAE: FormatsType;
  public MP4: FormatsType;
  public setItemUrl: boolean;
  public finalGlbSize: number;
  public viewerCounter: number;
  public prioritiesDictionary: { [id: number]: string };
  public adjustmentsPopupId: string;
  public priceOptions: { value: number, showValue: string }[];
  public viewInit: boolean;
  public polyTypesArray: KeyValuePoly[];
  public hasUV: boolean;
  public pausedReasons: Array<artists_pause_reasons>;
  public inProgress: boolean;
  public counterComments: number = 0;
  constructor(
    private roles: RolesManagerService,
    private gql: GraphqlService,
    private auth: AuthService,
    private enums: EnumsService,
    private rest: RestService,
    private resourceTypesService: ResourceTypesService,
    private pricingHelper: PricingHelperService,
    private utils: UtilsService,
    private broadcaster: BroadcasterService,
    public router: Router,
    private dialog: MatDialog,
    private mapper: MapperService,
    private categoriesService: CategoriesService,
    private pixels: PixelsService,
    private assetAdjustmentsService: AssetAdjustmentsService,
    private assetAdjustmentsHelperService: AssetAdjustmentsHelperService,
    private decimalPipe: DecimalPipe,
    private softwaresService: SoftwaresService,
    private resumableUpload: ResumableUploadService,
    private rolesHelper: RolesHelperService,
    private seo: SeoService,
    private chatService: ChatService,
    public body: BodyService,
    private safeWindowPipe: SafeWindowPipe,
    private offerService: OfferService,
    private surveyService: SurveyService,
    private endpoints: EndpointsService
  ) {
    this.viewerCounter = 0;
    this.glTF = FormatsType.glTF;
    this.GLB = FormatsType.GLB;
    this.FBX = FormatsType.FBX;
    this.OBJ = FormatsType.OBJ;
    this.USDZ = FormatsType.USDZ;
    this.DAE = FormatsType.DAE;
    this.MP4 = FormatsType.MP4;
    this.subs = [];
    this.adjustmentsSubs = [];
    this.productsToAdd = [];
    this.jobTags = [];
    this.jobPredefinedTags = [];
    this.warnings = [];
    this.auditLog = [];
    this.ballCourt = [];
    this.artistsItemsSpecs = [];
    this.onFetchingManager = [];
    this.onLightsSummary = [];
    this.onViewerFullyLoaded = [];
    this.jobImages = [];
    this.overlapMeshesNames = [];
    this.onArtistGroupsFetch = [];
    this._pausedReasonsFetching = [];
    this.negativeScaleCount = 0;
    this.mlTeaserOpened = false;
    // this._numOfMultipleUV = 0;
    this._execWarningsWhenDone = false;
    this._isWarningsRunning = false;
    this.IFRAME = MediaTag.IFRAME;
    this.MODEL = MediaTag.MODEL;
    this.IMAGE = MediaTag.IMAGE;
    this.VIDEO = MediaTag.VIDEO;
    this.resValue = new Subject<Array<Resource>>();
    this.onJobChange = new Subject<Job>();
    this.onResourceUploaded = new Subject<Resource>();
    this.onResourceAdjustScene = new Subject<Resource>();
    this.onJobRefresh = new Subject<void>();
    this.polyTypesDictionary = this.enums.getPolyTypesDictionary();
    this.polyTypesArray = Object.values(this.enums.getPolyTypesDictionary());

    this.polyShapeTypesDictionary = this.enums.getPolyShapeTypesDictionary();
    this.prioritiesDictionary = this.enums.getPrioritiesDictionary();
    if (this.softwaresService.getCachedSoftwares())
      this.setSoftwaresDictionary();
    else
      this.subs.push(this.broadcaster.on('onArtistsSoftwaresEnum').subscribe(this.setSoftwaresDictionary.bind(this)));
    this.formatsDictionary = {};
    this.allAvailableFormats = [];
    this.allFormats = this.resourceTypesService.getCachedTypes();
    if (this.allFormats) {
      this.allFormats.forEach(f => this.formatsDictionary[f.id] = f);
    } else {
      this.subs.push(
        // this.broadcaster.on('onResourceTypes').subscribe(
        this.resourceTypesService.getResourceTypes().subscribe(
          () => {
            this.allFormats = this.resourceTypesService.getCachedTypes();
            this.resourceTypesService.getCachedTypes().forEach(f => this.formatsDictionary[f.id] = f);
            this.setCurrentItemById(this.currentArtistsjobitemId);
          }
        )
      );
    }

    this.dimUnitsDictionary = {};
    this.enums.getDimensionsUnits().forEach(u => this.dimUnitsDictionary[u.key] = u);

    this.setRoles();
    this.broadcaster.on('onLogin').subscribe(this.setRoles.bind(this));
    this.init();
  }

  setRoles() {
    this.isSU = this.rolesHelper.isSULogedin();
    this.isAdmin = this.rolesHelper.isAdminLogedin();
    this.isQualitySupervisor = this.rolesHelper.isQualitySupervisorLogedin();
    this.isTechnicalReviewer = this.rolesHelper.isTechnicalReviewerLogedin();
    this.isSFM = this.rolesHelper.isSeniorFeedbackMaster();
    this.isGod = this.rolesHelper.isGodLogedin();
  }

  async init() {
    return new Promise(async (resolve, reject) => {
      if (this.pausedReasons)
        resolve(this.pausedReasons);
      this._pausedReasonsFetching.push(resolve);
      if (this._pausedReasonsFetching.length === 1) {
        this.pausedReasons = await this.getPauseReasons();
        this._pausedReasonsFetching.forEach(f => f(this.pausedReasons));
      }
    });
  }

  /**
   * Setting the options for price change dropdown (for FM users)
   */
  private setTotalPriceOptions() {
    this.priceOptions = [];
    for (let i = 0; i <= JobService.PRICE_DELTA; i++) {
      this.priceOptions.push({ value: i, showValue: `${i}$` });
    }
  }

  get currentModelIndex() {
    return this._currentModelIndex;
  }

  set currentModelIndex(value: number) {
    this._currentModelIndex = value;
    this.setHasUV();
  }

  get currentArtistsjobitem() {
    return this._currentArtistsjobitem;
  }

  set currentArtistsjobitem(value: ArtistJobItem) {
    this._currentArtistsjobitem = value;
    this.setHasUV();
  }

  setHasUV() {
    this.hasUV = !!this.currentArtistsjobitem?.artists_jobs_resources[this?.currentModelIndex]?.artists_jobs_resources_uv_layouts?.length;
  }

  get isAdjustmentsOnDom() {
    return this._isAdjustmentsOnDom;
  }

  get normalizedDimensions() {
    return this._normalizedDimensions;
  }

  get fetchingManager() {
    return this._fetchingManager;
  }

  get hasFullDimensions() {
    return this._hasFullDimensions;
  }

  get viewerUrl() {
    return this._viewerUrl;
  }

  get totalTexturesSize() {
    return this._totalTexturesSize;
  }

  get totalUVSize() {
    return this._totalUVSize;
  }

  get LIGHTNESS_THRESHOLD() {
    return LIGHTNESS_THRESHOLD;
  }

  get AB_THRESHOLD() {
    return AB_THRESHOLD;
  }

  get isWarningsRunning() {
    return this._isWarningsRunning;
  }

  get isArtistUnderNDA() {
    return this._isArtistUnderNDA;
  }

  OnInit() {
    this.onAdjustmentsInitSub = this.broadcaster.on('OnAssetAdjustmentsComponentInit').subscribe(() => {
      if (this.adjustmentsSubs.length)
        this.adjustmentsSubs.forEach(s => s.unsubscribe());
      this.adjustmentsSubs.push(this.assetAdjustmentsService.broadcaster.subscribe(this.onAdjustmentMessage.bind(this)));
      this.adjustmentsSubs.push(this.assetAdjustmentsService.pixel.subscribe(this.onAdjustmentPixel.bind(this)));
    });
    // refresh bonus every 30 seconds
    this.tickInterval = setInterval(() => this.refreshBonus.bind(this), 30000);
  }

  loadJobTags(tags: Array<ArtistJobTag>) {
    this.jobTags = [];
    tags.forEach(t => {
      this.jobTags.push(t);
    });

    if (this.jobPredefinedTags.length === 0) {
      let predefinedTags = Object.values(PredefinedJobTags);
      predefinedTags.forEach(t => {
        let at = {} as ArtistJobTag;
        at.is_predefined = true;
        at.tag = t;
        if (this.job)
          at.job_id = this.job.id;
        this.jobPredefinedTags.push(at);
      });
    }
  }

  /**
   * Return true if user's role is suitable for total price change
   * @returns
   */
  public isFMUserRole(): boolean {
    return this.rolesHelper.isSULogedin() || this.rolesHelper.isAdminLogedin() || this.rolesHelper.isFeedbackMaster() || this.rolesHelper.isGodLogedin() || this.rolesHelper.isQualitySupervisorLogedin();
  }

  onAdjustmentPixel(evt: PixelArtistsEvents) {
    this.pixels.sendPixel(evt);
  }

  async onAutoAdjustScene(viewerUrl: string) {
    this.saveOnAutoAdjustScene = false;
    // hotfix for fbx decrypt bug in three.js 135
    // this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url = this.utils.setUrlParam(viewerUrl, 'tv', viewerUrl.indexOf('.3df&') > -1 ? '131' : THREE_LATEST_VERSION);
    if (this.utils.getRenderingEngine(viewerUrl) === RenderingEngine.THREE)
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url = this.utils.setUrlParam(viewerUrl, 'tv', THREE_LATEST_VERSION);
    this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url = this.utils.setUrlParam(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url, 'json-data', new Date().getTime().toString());
    let cb = () => {
      this.onResourceAdjustScene.next(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]);
    };
    // this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url += '&tv=' + THREE_LATEST_VERSION;
    this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params = await this.assetAdjustmentsHelperService.onBeforeSave(this.assetAdjustmentsService.getJsonParams());
    this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].update_json_params = true;
    if (this.job.artists_jobs_types.find(ajt => ajt.type_id == JobsTypes.GEOMETRY) && !this.job.artists_jobs_types.find(ajt => ajt.type_id == JobsTypes.TEXTURE)) {
      // override lights for geometry only
      let lightsEdited = 0;
      if (this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params &&
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene &&
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene.lights) {
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url = this.utils.setUrlParam(
          this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url, 'json-data', new Date().getTime().toString()
        );
        if (this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene.lights.DirectionalLight &&
          this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene.lights.DirectionalLight[0]) {
          this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene.lights.DirectionalLight[0].intensity = 0.25;
          this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene.lights.DirectionalLight[0].followCamera = true;
          lightsEdited++;
        }
        if (this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene.lights.SpotLight &&
          this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene.lights.SpotLight[0]) {
          this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene.lights.SpotLight[0].intensity = 0.26;
          lightsEdited++;
        }
      }
      if (lightsEdited == 2) {
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene.lights.HemisphereLight = [];
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene.lights.AmbientLight = [];
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene.lights.PointLight = [];
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url = this.utils.setUrlParam(
          this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url, 'hdr', null
        );
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url = this.utils.setUrlParam(
          this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url, 'hdr-blur', null
        );
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url = this.utils.setUrlParam(
          this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url, 'le-probe', null
        );
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url = this.utils.setUrlParam(
          this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url, 'hdr-intensity', null
        );
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url = this.utils.setUrlParam(
          this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url, 'exp', '1'
        );
      }
    }
    // get scene params from last resource
    var caj = this.currentArtistsjobitem.artists_jobs_resources;
    let newresource = caj[this.currentModelIndex];
    if (caj.length > 0 && (newresource.resource_type === this.glTF || newresource.resource_type === this.GLB)) {
      let list = this.currentArtistsjobitem.artists_jobs_resources
        .filter(r => !r.is_archived && (r.resource_type === this.glTF || r.resource_type === this.GLB))
        .sort((a, b) => (new Date(b.created_at)).getTime() - (new Date(a.created_at)).getTime());

      if (list.length > 1) {
        newresource = list[0];
        let lastresource = list[1];
        try {
          let json = await this.getJsonParamsByUrl(lastresource.viewer_url) as any;
          if (json.scene) {
            newresource.json_params.scene.hdri = json.scene.hdri;
            newresource.json_params.scene.lights = json.scene.lights;
            newresource.json_params.scene.params = json.scene.params;
            newresource.json_params.scene.shadowPlane = json.scene.shadowPlane;
          }
        } catch (e) {
        }
      }
    }
    this.putResource(newresource, cb, cb);
  }

  async onAdjustmentMessage(message: any) {
    switch (message.key) {
      case 'onResourceExport': {
        this.onResourceExport(message.data);
        break;
      }
      case 'viewerFullyLoaded': {
        // this.assetAdjustmentsService.postToChild('getMeshesData');
        this._isWarningsRunning = false;
        this.onViewerFullyLoaded.forEach(f => f());
        this.onViewerFullyLoaded = [];
        break;
      }
      case 'onUVsOverlap': {
        this.resetWarningsRunning();
        await this.setWarnings();
        if (this.saveOnAutoAdjustScene) {
          let o = {
            type_id: ArtistsUsersPresetType.PUBLIC,
            retailer_sub_category_id: this.getCurrentRetailerSubCategoryId(),
            sub_category_id: this.getCurrentSubCategoryId(),
            retailer_id: this.getCurrentRetailerId(),
            job_id: this.job.id
          } as AdjustmentsPresetOptions;
          let presets = await this.assetAdjustmentsHelperService.getPresets(o);
          if (presets.length && (presets[0].retailer_sub_category_id || presets[0].sub_category_id || presets[0].retailer_id)) {
            this.saveOnAutoAdjustScene = false;
            let viewerUrl = this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url;
            if (this.utils.getRenderingEngine(viewerUrl) === RenderingEngine.THREE)
              viewerUrl = this.utils.setUrlParam(viewerUrl, 'tv', THREE_LATEST_VERSION);
            viewerUrl = this.utils.setUrlParam(viewerUrl, 'json-data', new Date().getTime().toString());
            let params = presets[0].preset_json.urlParats;
            for (let i in params) {
              viewerUrl = this.utils.setUrlParam(viewerUrl, i, params[i]);
            }
            // Make suge we don't use Babylon for unsupported file types
            // Currently our viewer support only glTF with Babylon
            // const load = this.utils.getUrlParam(viewerUrl, 'load');
            // if (this.utils.getFileExtension(load)[0] !== 'glb')
            // On second thought, let's not use Babylon at all until the color sampling
            // It leck to many features
            viewerUrl = this.utils.setUrlParam(viewerUrl, 'engine', null);
            this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url = viewerUrl;

            // this.assetAdjustmentsService.setLightsByJson(presets[0].preset_json.lights);

            if (!this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params)
              this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params = {} as ResourceJsonParams;
            if (!this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene)
              this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene = {} as ResourceJson;
            // let ls = await this.assetAdjustmentsService.refreshLightsSummary() as { [id: string]: Array<ThreeLight> };
            this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene.lights = presets[0].preset_json.lights;
            this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params = await this.assetAdjustmentsHelperService.onBeforeSave(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params);
            this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].update_json_params = true;
            let cb = () => {
              this.onResourceAdjustScene.next(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]);
            };
            this.putResource(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex], cb, cb);
          } else
            this.assetAdjustmentsService.adjustScene();
        }
        break;
      }
      case 'notifyUser': {
        this.broadcaster.broadcast('notifyUser', message.data);
        break;
      }
      case 'onAutoAdjustScene': {
        if (this.saveOnAutoAdjustScene) {
          this.onAutoAdjustScene(message.data);
        }
        break;
      }
      case 'onLightsSummary': {
        this.onLightsSummary.forEach(f => f());
        this.onLightsSummary = [];
        break;
      }
      //   case 'onMultipleUV': {
      //     this._numOfMultipleUV = message.data.count;
      //     if (this._numOfMultipleUV)
      //       this.setWarnings();
      //     break;
      //   }
      case 'onOverlapMeshesNames': {
        this.overlapMeshesNames = message.data.dictionary;
        this.negativeScaleCount = message.data.negativeScaleCount;
        if (this.overlapMeshesNames.length || this.negativeScaleCount)
          this.setWarnings();
        break;
      }
    }
  }

  setRenderType() {
    if (this.currentArtistsjobitem)
      this.renderType = this.utils.getRenderType(this.currentArtistsjobitem.artists_jobs_items_resources_types);
    else
      this.renderType = null;
  }

  setSoftwaresDictionary() {
    this.softwaresDictionary = this.softwaresService.getSoftwaresDictionary();
  }

  getArchiveReasons(id): Observable<UserRole[]> {
    return this.gql.getArchiveReasons(id).pipe(map((res: ApolloQueryResult<{ artists_users: { artists_users_roles: Array<UserRole> } }>) => res.data.artists_users.artists_users_roles));
  }

  // getGlbFileSize(): Promise<number> {
  //   return new Promise((resolve, reject) => {
  //     this.exportFileSizeResolve = resolve;
  //     this.exportFileSize = true;
  //     let options = {
  //       useOptipng: false
  //     } as GltfExportOptions;
  //     this.assetAdjustmentsService.export(options);
  //   });
  // }

  async onResourceExport(obj: any) {
    // if (this.exportFileSize) {
    //   this.exportFileSize = false;
    //   this.exportFileSizeResolve(new Blob([this.utils.base64ToArrayBuffer(obj.file)]).size);
    // }
    // else {
    if (this.cancelExportIfMUV) {
      this.cancelExportIfMUV = false;
      if (obj.numOfMultipleUVsSets > 0) {
        this.utils.notifyUser({ text: `Couldn't merge all UV sets`, type: NotificationType.Error });
        this.setWarnings();
        return;
      } else {
        // this._numOfMultipleUV = 0;
      }
    }
    let src_geometry_resource_id = this.assetAdjustmentsService.meshChanged ? null : this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].id;
    if (this.currentResource.resource_type != FormatsType.glTF && this.currentResource.resource_type != FormatsType.GLB && this.currentResource.resource_type != FormatsType.FBX)
      src_geometry_resource_id = null;
    let res = {
      job_item_id: this.currentArtistsjobitemId,
      archive: obj.type,
      file: obj.file,
      cam_change: false,
      resource_type: FormatsType.GLB,
      encrypt: true,
      compress: false,
      mesh_compress: this.assetAdjustmentsService.meshCompress,
      org_scene: true,
      as_base64: true,
      source_resource_id: this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].id,
      quantize_texcoord_bits: obj.quantizeTexcoordBits,
      quantize_normal_bits: obj.quantizeNormalBits,
      dracoCompressionLevel: obj.dracoCompressionLevel,
      poly_type: this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].poly_type,
      json_params: await this.assetAdjustmentsHelperService.onBeforeSave(this.utils.getResourceJsonParams(obj.json_params, obj.targetResourceType)),
      // is that needed?
      // json_params: await this.assetAdjustmentsHelperService.onBeforeSave(this.utils.getResourceJsonParams(await this.assetAdjustmentsService.getJsonParamsAsync(), obj.targetResourceType)),
      target_resource_type: obj.targetResourceType,
      src_images_resource_id: this.assetAdjustmentsService.hasImagesChanged() ? null : this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].id,
      src_geometry_resource_id
    } as Resource;
    this.uploadNewResource(res);
  }

  getCurrent() {
    return this.currentJob;
  }

  deleteCurrent() {
    delete this.currentJob;
  }

  setCurrentAndGetFull(job: Job, getFull = true) {
    this.currentJob = job;
    this.currentJob.isCached = true;
    if (getFull) {
      this.gql.job(this.currentJob.id).subscribe(
        (j: ApolloQueryResult<JobQueryData>) => {
          this.onJobChange.next(j.data.artists_jobs);
        }
      );
    }
  }

  canSeePrice(job: Job, user: User) {
    if (!user) return false;
    return job.artist_user_id == user.id || this.roles.doesRolesHasPermission(user.roles, 'View Job Price');
  }

  // isFixing() {
  //   return !!this.warnings.find(w => w.isActive);
  // }

  openAdjustments(button_name: string, combineImagesMode: CombineImagesMode, resourceWarningType?: ResourceWarningType) {
    this.adjustmentsPopupId = this.utils.generateUUID();
    this.pixels.sendPixel({
      event: 'enterAdjustments',
      job_id: this.job.id,
      screen_id: this.adjustmentsPopupId,
      button_name
    });
    this.toggleAdjustmentsMode(AdjustmentsMode.FULLSCREEN);
    const dialogRef = this.dialog.open(AssetAdjustmentsWrapComponent, {
      width: 'calc(100vw - 20px)',
      data: {
        subCategoryId: this.getCurrentSubCategoryId(),
        retailerSubCategoryId: this.getCurrentRetailerSubCategoryId(),
        combineImagesMode,
        resourceWarningType,
        retailerId: this.getCurrentRetailerId(),
        jobId: this.job.id,
        isAmazon: this.isAmazon()
      } as AdjustmentsData
    });
    dialogRef.afterClosed().subscribe(() => {
      this.toggleAdjustmentsMode(AdjustmentsMode.NORMAL);
      this.pixels.sendPixel({
        event: 'exit_screen',
        job_id: this.job.id,
        screen_id: this.adjustmentsPopupId,
        button_name
      });
    });
    this.setIsAdjustments();
  }

  public sendFixEvent(fixStage: FixStage, fixType: string, fixId?: string): string {
    if (!fixId) {
      fixId = this.utils.generateUUID();
    }

    this.pixels.sendPixel({
      event: 'fix',
      job_id: this.job.id,
      fix_stage: fixStage,
      fix_id: fixId,
      fix_type: fixType
    });
    return fixId;
  }

  fixMissingAlignments(rw?: ResourceWarning) {
    this.openAdjustments('fix_missing_alignments', CombineImagesMode.MODEL_ON_TOP);
    this.sendFixEvent(FixStage.TRY_TO_FIX, rw?.desc)
  }

  fixMissingGeometryAccuracy(rw?: ResourceWarning) {
    this.openAdjustments('fix_missing_geometry_accuracy', CombineImagesMode.SIDE_BY_SIDE, ResourceWarningType.MissingGeometryAccuracy);
  }

  fixMissingColorComparison(rw?: ResourceWarning) {
    this.openAdjustments('fix_missing_color_comparison', CombineImagesMode.SIDE_BY_SIDE, ResourceWarningType.MissingColorComparison);
  }

  fixOverlapMeshesNames(rw?: ResourceWarning) {
    this.assetAdjustmentsService.fixOverlapMeshesNames();
    rw.isActive = true;
    this.overlapMeshesNames = [];
    this.sendFixEvent(FixStage.TRY_TO_FIX, rw?.desc)
  }

  async fixDiffuseThreshold(rw?: ResourceWarning) {
    this.pixels.sendPixel({
      event: 'fixDiffuseThresholdErrorStart'
    });
    rw.isActive = true;
    this.sendFixEvent(FixStage.TRY_TO_FIX, rw?.desc)
    let mode = CLAMPING_MODE.GRADUAL;
    if (this.isSU && confirm('Do you want to use the experamental clamping function?'))
      mode = CLAMPING_MODE.DISTINCT;
    const res = await this.assetAdjustmentsService.fixImageThreshold(JobService.DIFFUSE_MIN_THRESHOLD, JobService.DIFFUSE_MAX_THRESHOLD, mode) as any;
    const onSuccess = async () => {
      this.pixels.sendPixel({
        event: 'fixDiffuseThresholdErrorMid'
      });
      await this.utils.setTimeout();
      this.assetAdjustmentsService.export({
        useOptipng: !this.isAmazon()
      });
      this.onViewerFullyLoaded.push(() => {
        this.pixels.sendPixel({
          event: 'fixDiffuseThresholdErrorDone'
        });
        this.setWarnings();
      });
    };
    if (res) {
      if (res.success) {
        onSuccess();
      } else {
        rw.isActive = false;
        this.utils.notifyUser({
          text: `Fix failed due to insufficient memory. Please make sure your diffuse map do not contain pixels with values lower than ${JobService.DIFFUSE_MIN_THRESHOLD} or higher than ${JobService.DIFFUSE_MAX_THRESHOLD} as the RGB values.`,
          type: NotificationType.Error,
          action: 'O.K.'
        });
        this.pixels.sendPixel({
          event: 'fixDiffuseThresholdErrorFailure'
        });
      }
    } else
      onSuccess();
  }

  async fixScaleUnits(rw?: ResourceWarning) {
    if (this.exportProgress || !this.assetAdjustmentsService.isViewerReady) {
      this.assetAdjustmentsService.postToChild('broadcastViewerFullyLoaded');
      let data: Notification = {
        text: 'model needs to load first',
        type: NotificationType.Info,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
      return;
    }
    // This is marked out due to a racing condition that might cause 0 lights on the scene
    // const scaleLights = async (scale: number) => {
    //   return new Promise((resolve, reject) => {
    //     this.onLightsSummary.push(async () => {
    //       await this.assetAdjustmentsService.saveAll();
    //       this.putResource(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex], () => {
    //         resolve(null);
    //       });
    //     });
    //     this.assetAdjustmentsService.postToChild('setLightsScaleAndBroadcast', scale);
    //   });
    // };
    if (rw) {
      rw.isActive = true;
    }
    const actual = this.getActualDimensions(true);
    const target = this.getTargetlDimensions(true);
    if (target && actual) {
      if (actual.units === target.units) {
        const ratio = this.getRatio(actual, target);
        // The old way is to fix this with the viewer:
        // this.compressOnFix = false;
        // this.assetAdjustmentsService.setScale(ratio, ratio, ratio);
        // this.assetAdjustmentsService.export();
        // The new way is to fix it on the server side with Blender:
        const fixId = this.utils.generateUUID();
        this.sendFixEvent(FixStage.TRY_TO_FIX, rw.desc, fixId)
        this.rest.resourceScale('post', {
          resource_id: this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].id,
          scale: ratio
        }).subscribe(
          () => {
            this.sendFixEvent(FixStage.FIXED, rw.desc, fixId)
            this.preservedModelIndex = 0;
            this.refresh(async () => {
              // this.onViewerFullyLoaded.push(() => {
              //   scaleLights(ratio);
              // });
            });
          },
          async err => {
            this.sendFixEvent(FixStage.NOT_FIXED, rw.desc, fixId)
            let msg = err && err.error ? err.error : 'failed to fix scale';
            if (msg && msg.error && msg.error.message) {
              msg = msg.error.message;
            }
            if (msg && msg.indexOf('Reason: ') === 0) {
              msg = msg.substring(8);
            }
            msg += '. applying fallback, please stand by . . .';
            const data: Notification = {
              text: msg,
              type: NotificationType.Info,
              action: 'OK',
              duration: 10000
            }
            this.broadcaster.broadcast('notifyUser', data);
            this.compressOnFix = false;
            this.assetAdjustmentsService.setScale(ratio, ratio, ratio);
            await this.utils.setTimeout(1000);
            this.assetAdjustmentsService.export();
            this.pixels.sendPixel({
              event: 'fixScaleUnitsServerError'
            });
          }
        );
      }
    }
  }

  getBinSize(): number {
    if (this.resourceDetails && this.resourceDetails.original_files && this.resourceDetails.original_files['Product.bin']) {
      const arr = this.resourceDetails.original_files['Product.bin'].split(' ');
      let size = parseFloat(arr[0]);
      if (arr[1] !== 'MiB') {
        size /= 1024;
      }
      return size;
    }
  }

  async fixPolygonShape(rw?: ResourceWarning) {
    if (rw) {
      rw.isActive = true;
    }
    this.sendFixEvent(FixStage.TRY_TO_FIX, rw?.desc)
    const poly = this.currentArtistsjobitem.UI.polyTypes.find(i => i.key === this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].poly_type);
    const config = {
      resource_id: this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].id,
      artist_user_id: this.auth.user.id,
      save_mode: true,
      action: ResourcePolygonReductionActions[ResourcePolygonReductionActions.change_poly_type],
      target_poly_scale: poly.value.shapeType
    } as ResourcePolygonReduction;
    await this.utils.observableToPromise(this.rest.polygonReduction('post', config));
    rw.isActive = false;
    this.refresh(this.setWarnings.bind(this));
  }

  async fixRedundantTextures(rw?: ResourceWarning) {
    if (rw) {
      rw.isActive = true;
    }
    this.sendFixEvent(FixStage.TRY_TO_FIX, rw?.desc)
    this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].update_json_params = true;
    this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params = await this.assetAdjustmentsService.getJsonParamsAsync();
    for (const i in this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene.lights) {
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params.scene.lights[i].forEach(l => {
        if (l.castShadow) {
          l.castShadow = false;
        }
      });
    }
    this.putResource(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex], this.refresh.bind(this));
  }

  fixFileSize(rw?: ResourceWarning) {
    if (this.exportProgress || !this.assetAdjustmentsService.isViewerReady) {
      this.assetAdjustmentsService.postToChild('broadcastViewerFullyLoaded');
      const data: Notification = {
        text: 'model needs to load first',
        type: NotificationType.Info,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
      return;
    }

    this.sendFixEvent(FixStage.TRY_TO_FIX, rw.desc)
    if (rw) {
      rw.isActive = true;
    }
    const bSize = this.getBinSize();
    const options = {
      imagesType: ImagesFileTypes.JPG,
      normalImagesType: ImagesFileTypes.PNG,
      imagesCompressionFactor: 0.92,
      meshCompress: false,
      maxTexturesSize: 1024,
      downloadFile: false,
      maxDiffuseTexturesSize: 2048,
      maxNormalTexturesSize: 2048,
      useOptipng: true
    } as GltfExportOptions;
    if (bSize > JobService.MAX_BIN_SIZE) {
      options.meshCompress = true;
    }
    this.oldTotalTexturesSize = this.totalTexturesSize;
    this.assetAdjustmentsService.export(options);
    this.compressOnFix = true;
  }

  isDimensionsValid(actual: ResourceDimensions, target: ResourceDimensions): Array<ResourceWarning> {
    const res = [] as Array<ResourceWarning>;
    if (this.job.artists_jobs_types.length === 1 && this.job.artists_jobs_types.find(ajt => ajt.type_id === JobsTypes.GEOMETRY)) {
      this._hasFullDimensions = true;
      return res;
    }
    if (actual && typeof actual === 'object' && target && typeof target === 'object' &&
      actual.height && actual.width && actual.length && actual.units &&
      target.height && target.width && target.length && target.units) {
      this._hasFullDimensions = true;
      if (actual.units !== target.units) {
        res.push({
          type: ResourceWarningType.Dimensions,
          desc: 'Units should be ' + DimensionsUnits[target.units] + ', actual units are ' + DimensionsUnits[actual.units],
          level: ResourceWarningLevel.Warning
        } as ResourceWarning);
      } else {
        const maxTarget = this.getMaxDim(target);
        const maxActual = this.getMaxDim(actual);
        if (Math.max(Math.abs(maxActual / maxTarget), Math.abs(maxTarget / maxActual)) > JobService.DIMENSIONS_TOLERANCE) {
          res.push({
            type: ResourceWarningType.Dimensions,
            // desc: 'bounding box volue should be around ' + this.decimalPipe.transform(tBB, '1.0-2') + ' ' + DimensionsUnits[actual.units] + ', actual bounding box size is ' + this.decimalPipe.transform(aBB, '1.0-2') + ' ' + DimensionsUnits[actual.units],
            desc: 'Model required dimensions does not match actual dimensions',
            // level: (this.currentResource.resource_type === FormatsType.GLB || this.currentResource.resource_type === FormatsType.glTF) ? (this.job.bypass_dimensions ? ResourceWarningLevel.Warning : ResourceWarningLevel.Error) : ResourceWarningLevel.Warning,
            level: (this.currentResource.resource_type === FormatsType.GLB || this.currentResource.resource_type === FormatsType.glTF) ? (this.job.bypass_dimensions && !this.isAmazon() ? ResourceWarningLevel.Warning : ResourceWarningLevel.Error) : ResourceWarningLevel.Warning,
            action: this.fixScaleUnits.bind(this),
            videoSrc: 'https://www.youtube.com/embed/l6mxpWSJ-_c'
          });
        }
      }
    } else {
      this._hasFullDimensions = false;
    }
    return res;
  }

  getMaxDim(dim: ResourceDimensions): number {
    let max = dim.length;
    if (max < dim.width) {
      max = dim.width;
    }
    if (max < dim.height) {
      max = dim.height;
    }
    return max;
  }

  getManager() {
    if (!this.manager && !this._fetchingManager && this.job.manager_id) {
      this._fetchingManager = true;
      this.gql.user(this.job.manager_id).subscribe(
        u => {
          this.manager = u.data.artists_users;
          this._fetchingManager = false;
          this.onFetchingManager.forEach(f => f());
          this.onFetchingManager = [];

        }
      )
    }
  }

  getqualitySupervisor() {
    if (!this.qualitySupervisor && this.job.quality_supervisor) {
      this.gql.user(this.job.quality_supervisor).subscribe(
        u => {
          this.qualitySupervisor = u.data.artists_users;
        }
      )
    }
  }

  getfeedbacker() {
    if (!this.feedbacker && this.job.feedbacker_id) {
      this.gql.user(this.job.feedbacker_id).subscribe(
        u => {
          this.feedbacker = u.data.artists_users;
        }
      )
    }
  }

  public getUnityRID(): number {
    return environment.production ? 840 : 606;
  }

  getRID() {
    return this.job.artists_jobs_items[0].artists_items[0].products[0].retailers[0].id;
  }

  isAmazon() {
    let rsm = this.job.artists_jobs_items[0].artists_items[0].products[0]?.retailers[0]?.retailers_studios_mapping;
    if (rsm?.length) {
      let studio = rsm[0]?.studio_id;
      return !!studio;
    }
    return false;
  }

  isWanna() {
    return !!environment.wannaRIDs.find(rid => rid === this.getRID());
  }

  isWannaShoes() {
    return !!environment.wannaShoesRIDs.find(rid => rid === this.job.artists_jobs_items[0].artists_items[0].products[0].retailers[0].id);
  }

  isWannaBags() {
    return !!environment.wannaBagsRIDs.find(rid => rid === this.job.artists_jobs_items[0].artists_items[0].products[0].retailers[0].id);
  }

  isMixtiles() {
    return environment.mixtilesRID === this.getRID();
  }

  setJob(itemId?: number) {
    if (!this.job) {
      return;
    }

    this.reAssign = !this.job.paid;
    if (this.job.artists_jobs_items instanceof Array && this.job.artists_jobs_items.length > 0) {
      this.job.artists_jobs_items.sort((a: ArtistJobItem, b: ArtistJobItem) => {
        if (a.created_at < b.created_at) {
          return -1;
        } else if (a.created_at > b.created_at) {
          return 1;
        } else {
          return 0;
        }
      });
      const aji = this.job.artists_jobs_items.find(i => i.id == this.currentArtistsjobitemId);
      if (itemId) {
        this.setCurrentItemById(itemId, true);
      }
      else {
        if (aji) {
          this.setCurrentItemById(aji.id, true);
        } else {
          this.setCurrentItemById(this.job.artists_jobs_items[0].id);
        }
      }
    }
    this.job.counter_updated_at = this.utils.getSafeDate(this.job.counter_updated_at);
    if (!this.job.artists_jobs_pricing || !this.job.artists_jobs_pricing.length) {
      this.job.artists_jobs_pricing = [{
        base_price: this.job.price,
        rank_bonus: 0,
        time_bonus: 0,
        allow_rank_bonus: this.job.allow_rank_bonus,
        allow_time_bonus: this.job.allow_time_bonus
      }];
    }
    this.initResources();
    this.initSmallImagesUrls();
    this.refreshAudit();
    this.setArtistsJobItemsUI();
    this.setArtistsItemsSpecs();

    if (this.currentArtistsjobitem) {
      if (this.currentArtistsjobitem.artists_items[0].category_id) {
        this.parentCat = this.categoriesService.getParentsByCategoriesId([this.currentArtistsjobitem.artists_items[0].category_id], true)[0];
      }
      if (this.categoriesDictionary[this.currentArtistsjobitem.artists_items[0].category_id]) {
        this.catName = this.categoriesDictionary[this.currentArtistsjobitem.artists_items[0].category_id].description;
      }
      if (this.currentArtistsjobitem.artists_items[0].artists_items_sub_categories_associations && this.currentArtistsjobitem.artists_items[0].artists_items_sub_categories_associations[0] && this.currentArtistsjobitem.artists_items[0].artists_items_sub_categories_associations[0].sub_category_id) {
        this.subCatId = this.currentArtistsjobitem.artists_items[0].artists_items_sub_categories_associations[0].sub_category_id;
        if (this.subCategoriesDictionary[this.subCatId]) {
          this.subCatName = this.subCategoriesDictionary[this.subCatId].description;
        }
      }
    }
    if (!this.pixelOnce) {
      this.pixelOnce = true;
      this.pixels.sendPixel({
        event: 'card_view',
        reference_id: this.job.id
      });
    }
    this.mapUI(this.job, true);
    this.refreshBonus();
    this.setTimerPause();
    this.canDownloadSourcefiles =
      this.job.artist_user_id === this.auth.user.id ||
      (this.rolesHelper.isAdminLogedin() && this.getRetailerConfigSourceFileApproval()) ||
      this.rolesHelper.doesUserHasPermission('Download Source Files');
    this.canDownloadResourceFiles = this.canDownloadSourcefiles;
    // In case the product is demo we will allow QSs to download files
    if ((this.isQualitySupervisor || this.isSFM) && this.job.artists_jobs_items[0].artists_items[0].products[0]?.is_poc) {
      this.canDownloadResourceFiles = true;
      this.canDownloadSourcefiles = true;
    }
    // if (!this.canDownloadResourceFiles && this.job.artists_jobs_items[0].artists_items[0].products && this.job.artists_jobs_items[0].artists_items[0].products[0].retailers) {
    //   // Allow download Amazon and Unity resources for QS
    //   const rid = this.getRetailerID();
    //   if (rid === this.getUnityRID() && this.isAdmin) {
    //     this.canDownloadResourceFiles = true;
    //   }
    //   // Allow download logitech resources for some FM and QS
    //   if (rid === 569 && (this.auth.user.id === 1363 || this.auth.user.id === 329 || this.auth.user.id === 2451)) {
    //     this.canDownloadResourceFiles = true;
    //   } else if (rid === 205 && this.auth.user.id === 18) {
    //     // Allow download Macy's resources for Watson_Th
    //     this.canDownloadResourceFiles = true;
    //   }
    // }

    const mOptions = new MetaOptions();
    mOptions.description = `Creators3D is a 3D content creation platform that supplies Job Offers for 3D Artists. On this page you can upload resources to your Job.`;
    mOptions.keywords = 'Creators3D, Freelancer, 3D Artist, 3D Designer, Job';
    mOptions.canonical = `https://www.creators3d.com/offer/${this.job.id}`;
    mOptions.title = `Creators 3D | Job - ${this.job.artists_jobs_items[0].artists_items[0].name}`;
    mOptions.og_title = mOptions.title;
    if (this.job.UI.jobImages.length) {
      mOptions.og_image = this.job.UI.jobImages[0].big;
    }
    this.seo.setMetaDate(mOptions);

    delete this.jobTypeDesc;
    if (this.job.artists_jobs_types) {
      const typesDictionary = {};
      this.job.artists_jobs_types.forEach(ot => typesDictionary[ot.type_id] = true);
      const count = Object.keys(typesDictionary).length;
      if (count === 1) {
        if (typesDictionary[1]) {
          this.jobTypeDesc = 'You are required to create mesh only<b class="success-accent-color disp-block">without texturing</b>';
        }
        if (typesDictionary[2]) {
          this.jobTypeDesc = 'You are required to create texture only<b class="success-accent-color disp-block">+ geometry is provided</b>';
        }
      }
    }
    this.getManager();
    this.getfeedbacker();
    this.getqualitySupervisor();
    this.getChat();
    this.setMeshLib();
    if (this.isAdmin && typeof this._isArtistUnderNDA !== 'boolean')
      this.setIsArtistUnderNDA();

  }

  async setIsArtistUnderNDA() {
    if (this.job.artist_user_id) {
      const aGUs = await this.auth.getArtistUserGroups(this.job.artist_user_id);
      this._isArtistUnderNDA = this.rolesHelper.isUserUnderNDA(aGUs);
    }
  }

  async needSurvey(): Promise<boolean> {
    if (this.isAdmin || (this.job.status !== 'Approved Job' && this.job.status !== 'Pending Source Files')) {
      return false;
    }

    const options = {
      artist_user_id: this.auth.user.id,
      reference_id: this.job.id,
      survey_type_id: SurveyService.JOB_FEEDBACK_SURVEY_ID
    } as SurveyResponse;
    const ans = await this.surveyService.allArtistsUsersSurveys(options);
    return !!!ans.count;
  }

  getCurrentSubCategoryId(): number {
    return this.job.artists_jobs_items[0].artists_items[0] &&
      this.job.artists_jobs_items[0].artists_items[0].artists_items_sub_categories_associations &&
      this.job.artists_jobs_items[0].artists_items[0].artists_items_sub_categories_associations[0] &&
      this.job.artists_jobs_items[0].artists_items[0].artists_items_sub_categories_associations[0].sub_category_id;
  }

  getCurrentRetailerSubCategoryId(): number {
    return this.job.artists_jobs_items[0].artists_items[0] &&
      this.job.artists_jobs_items[0].artists_items[0].artists_items_sub_categories_associations &&
      this.job.artists_jobs_items[0].artists_items[0].products[0].retailer_sub_category_id;
  }

  getCurrentRetailerId(): number {
    return this.job.artists_jobs_items[0].artists_items[0] &&
      this.job.artists_jobs_items[0].artists_items[0].artists_items_sub_categories_associations &&
      this.job.artists_jobs_items[0].artists_items[0].products[0].retailers[0] &&
      this.getRID();
  }

  getRetailerConfigSourceFileApproval(): boolean {
    return this.job.artists_jobs_items[0].artists_items[0]?.products[0]?.retailers[0].source_file_match_approval || false;
  }

  getRetailerConfigSourceFileAutoApproval(): boolean {
    return this.job.artists_jobs_items[0].artists_items[0]?.products[0]?.retailers[0].auto_validate_source_files || false;
  }

  getRetailerConfigQaMode(): QaModes {
    return this.job.job_qa_mode;
  }

  setMeshLib() {
    const catAssoc = this.job?.artists_jobs_items[0]?.artists_items[0]?.artists_items_sub_categories_associations;
    if (catAssoc?.length > 0) {
      const subcatid = catAssoc[0].sub_category_id;
      if (subcatid) {
        this.gql.meshLibraries([subcatid]).subscribe(
          scat => {
            if (scat.data?.allLibraries?.length > 0) {
              this.meshLibraries = scat.data.allLibraries;

            } else {
              delete this.meshLibraries;
            }
          }
        )
      } else {
        delete this.meshLibraries;
      }
    }
  }

  setMediaTag() {
    this.mediaTag = MediaTag.MODEL;
    if (this.currentArtistsjobitem && this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]) {
      this.mediaTag = this.utils.getMediaTagByResource(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]);
    }
  }

  refreshBonus(): void {
    this.rankBonusByUser = this.pricingHelper.getRankBonusByUser(this.job.artists_jobs_pricing[0].base_price,
      this.job.artists_users[0], undefined, this.job.allow_rank_bonus, this.job.UI.isGeometry, this.job.UI.isTexture);
    this.timeBonusByUser = this.pricingHelper.getTimeCurrentBonus(this.job.artists_jobs_pricing[0].base_price,
      this.job.created_at, this.job.UI.dueDate, this.job.allow_time_bonus);
    this.currentTotal = this.pricingHelper.getCurrentTotal(
      this.job.artists_jobs_pricing[0].base_price,
      this.job.artists_users[0],
      this.job.created_at,
      this.job.UI.dueDate, undefined, this.job.allow_time_bonus, this.job.allow_rank_bonus, this.job.UI.isGeometry, this.job.UI.isTexture);
    this.setTotalPriceOptions();
    this.timeBonusExpires = this.pricingHelper.getTimeBonusExpirationDate(this.job.created_at, this.flatGaugeTo);
  }

  async mapUI(job: Job, full: boolean) {
    let isGeometry = false;
    let isTexture = false;
    let isRender = false;
    let isHighPoly = false;

    if (job.artists_jobs_types) {
      isGeometry = !!job.artists_jobs_types.find(t => t.type_id == JobsTypes.GEOMETRY);
      isTexture = !!job.artists_jobs_types.find(t => t.type_id == JobsTypes.TEXTURE);
      isRender = !!job.artists_jobs_types.find(t => t.type_id == JobsTypes.RENDR);
    }
    if (!job.artists_jobs_pricing || !job.artists_jobs_pricing.length) {
      job.artists_jobs_pricing = [{
        base_price: job.price,
        rank_bonus: 0,
        time_bonus: 0,
        allow_rank_bonus: job.allow_rank_bonus,
        allow_time_bonus: job.allow_time_bonus
      }];
    }
    const dueDate = new Date();
    this.fixHoursToComplete(job, full);
    dueDate.setHours(dueDate.getHours() + job.hours_to_complete);
    let priority = 0;
    job.artists_jobs_items.forEach(ai => {
      if (ai.artists_items) {
        priority = Math.max(priority, ai.artists_items[0].priority);
      }
      isHighPoly = isHighPoly || !!ai.artists_jobs_items_polygon_specifications?.find(s => s.poly_type === PolyType.HIGH);
    });
    let pauseReason = null;
    if (job.is_paused && job.pause_reason_id) {
      pauseReason = this.pausedReasons.find(p => p.id === job.pause_reason_id);
    }
    if (job.artists_jobs_items[0].artists_items) {
      job.artists_jobs_items[0].artists_items.forEach(i => priority = Math.max(priority, i.priority));
    }
    job.UI = {
      jobImages: this.mapper.getJobImages(job),
      currentTotalPrice: (job.artists_users && job.artists_jobs_items && job.artists_jobs_items[0] && job.artists_jobs_items[0].artists_items && job.artists_jobs_items[0].artists_items[0]) ?
        this.pricingHelper.getCurrentTotal(job.artists_jobs_pricing[0].base_price,
          job.artists_users[0],
          job.created_at,
          dueDate, undefined, job.allow_time_bonus, job.allow_rank_bonus, isGeometry, isTexture) :
        job.price,
      userBonus: job.artists_jobs_items[0].artists_items[0].products && job.artists_users ?
        this.pricingHelper.getCurrentBonus(job.artists_users[0], job.artists_jobs_pricing[0].base_price, job.created_at, dueDate, undefined, job.allow_time_bonus, job.allow_rank_bonus, isGeometry, isTexture) :
        null,
      isMulti: this.isMultiple(job),
      dueDate,
      isGeometry,
      isTexture,
      isRender,
      priority,
      pauseReason,
      isHighPoly
    };
    job.UI.finalPrice = job.UI.currentTotalPrice + job.artists_jobs_pricing[0].price_change;
    if (job.artists_jobs_items[0].artists_items && job.artists_jobs_items[0].artists_items[0] && job.artists_jobs_items[0].artists_items[0].products) {
      job.artists_jobs_items.sort((i1, i2) => i2.artists_items[0].products[0].id - i1.artists_items[0].products[0].id);
    }

    job.uiInit = true;
  }

  async setCurrentItemById(id: number, force = false) {
    if (!this.job) {
      return;
    }
    if (this.currentArtistsjobitemId === id && !force) {
      return;
    }
    if (!this.job.artists_jobs_items.filter(i => i.id === id).length) {
      id = this.job.artists_jobs_items[0].id;
    }
    this.currentArtistsjobitemId = id;
    if (!this.currentArtistsjobitem || this.currentArtistsjobitem?.id != this.currentArtistsjobitemId) {
      this.currentArtistsjobitem = this.utils.deepCopyByValue(this.job.artists_jobs_items.filter(i => i.id === id)[0]);
      if (this.preservedModelIndex)
        delete this.preservedModelIndex;
    }
    else
      this.utils.deepCopy(this.job.artists_jobs_items.filter(i => i.id == id)[0], this.currentArtistsjobitem);
    this.setArtistsJobItemUI(this.currentArtistsjobitem, this.job);
    this.currentArtistsResourceRank = null;
    this.requiredFormats = [];

    if (this.currentArtistsjobitem) {
      if (this.currentArtistsjobitem.artists_items) {
        if (this.currentArtistsjobitem.artists_items[0] && this.currentArtistsjobitem.artists_jobs_items_resources_types && this.currentArtistsjobitem.artists_jobs_items_resources_types.length) {
          this.requiredFormats = this.resourceTypesService.mapProductResourceTypeToUi(this.currentArtistsjobitem.artists_jobs_items_resources_types);
          this.setAllAvailableFormats();
        }
        this.convertToCM(this.currentArtistsjobitem.artists_items[0]);
      }
      if (this.currentArtistsjobitem.artists_jobs_resources && this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex] &&
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].artists_jobs_resources_categories_ranking &&
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].artists_jobs_resources_categories_ranking.length) {
        this.currentArtistsResourceRank = this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].artists_jobs_resources_categories_ranking[0];
      }
    }


    delete this.currentFormat;
    this.imagesDownloadLink = this.rest.getCreatorsRoute() + '/artist/item/image/download/' + this.currentArtistsjobitem.item_id + '?token=' + this.rest.getToken();
    delete this.originalResources;
    await this.initResources();
    await this.setWarnings();
    this.setMediaTag();
    this.setArtistsItemsSpecs();
    if (this.setItemUrl && this.getItemIdFromURL() !== id) {
      this.router.navigate([`jobs/job/${this.currentJob ? this.currentJob.id : this.job.id}`], { queryParams: { item: id } });
    }
    this.jobImages = this.getJobImagesById(this.currentArtistsjobitemId);
  }

  getItemIdFromURL(): number {
    const iid = parseInt(this.utils.getUrlParam(location.search, 'item'));
    return isNaN(iid) ? null : iid;
  }

  getArtistsItemsSpecs(job: Job, currentArtistsjobitem: ArtistJobItem): Array<ArtistsItemSpecUI> {
    const artistsItemsSpecs = [] as Array<ArtistsItemSpecUI>;
    if (currentArtistsjobitem && currentArtistsjobitem.artists_items[0].artists_items_specs) {
      job.artists_jobs_types.forEach(ajt => {
        currentArtistsjobitem.artists_items[0].artists_items_specs.filter(ais => ais.job_type === ajt.type_id || ais.job_type === null).forEach(
          s => {
            if (!artistsItemsSpecs.find(inS => inS.id === s.id)) {
              const icon = this.utils.getIconByUrl(s.attachment);
              artistsItemsSpecs.push({
                attachment: s.attachment,
                attachment_type: s.attachment_type,
                created_at: s.created_at,
                id: s.id,
                item_id: s.id,
                job_type: s.job_type,
                sort_index: s.sort_index,
                title: s.title,
                updated_at: s.updated_at,
                isImage: icon === 'photo',
                fileExtension: this.utils.getFileExtension(s.attachment),
                icon,
                source_table: s.source_table,
                mandatory: this.offerService.isSpecMandatory(s),
                isOffer: false,
                referenceId: job.id,
                text: s.text
              });
            }
          }
        );
        artistsItemsSpecs.sort(this.offerService.sortSpecs);
        artistsItemsSpecs.sort(this.offerService.sortSpecsByType.bind(this.offerService));
        // this.artistsItemsSpecs = this.artistsItemsSpecs.concat(currentArtistsjobitem.artists_items[0].artists_items_specs.filter(ais => ais.job_type == ajt.type_id || ais.job_type === null));
      });
    }
    if (currentArtistsjobitem && currentArtistsjobitem.artists_items[0].artists_items_downloads) {
      const arr = currentArtistsjobitem.artists_items[0].artists_items_downloads.filter(aid => aid.attachment_type !== AttachmentFileType.GUIDE_IMAGE).sort(this.offerService.sortSpecs).sort(this.offerService.sortSpecsByType.bind(this.offerService));
      arr.forEach(id => {
        const icon = this.utils.getIconByUrl(id.url);
        artistsItemsSpecs.push({
          attachment_type: id.attachment_type,
          attachment: id.url,
          item_id: id.item_id,
          title: '',
          sort_index: id.sort_index,
          job_type: id.job_type,
          isImage: icon === 'photo',
          fileExtension: this.utils.getFileExtension(id.url),
          icon
        } as ArtistsItemSpecUI);
      });
    }
    return artistsItemsSpecs;
  }

  setArtistsItemsSpecs() {
    this.artistsItemsSpecs = this.getArtistsItemsSpecs(this.job, this.currentArtistsjobitem);
  }

  setArtistsJobItemsUI() {
    this.job.artists_jobs_items.forEach(aji => {
      this.setArtistsJobItemUI(aji, this.job);
    });
  }

  setArtistsJobItemUI(aji: ArtistJobItem, job?: Job) {
    aji.UI = {
      hasApproved: aji?.artists_jobs_resources ? !!aji.artists_jobs_resources.find(r => r.approved === 1) : false,
      hasFinalApproved: aji?.artists_jobs_resources ? !!aji.artists_jobs_resources.find(r => r.final_approval) : false,
      hasCMS: aji?.artists_jobs_resources ? !!aji.artists_jobs_resources.find(r => r.sent_to_retailers) : false,
      polyTypes: this.utils.getKeyValuePolyByPolySpecs(aji?.artists_jobs_items_polygon_specifications, true, this.getSubCatDefaults(job))
    }
  }

  uploadNewResource(resource: Resource) {
    const onSuccess = (resources: Resource) => {
      delete this.exportProgress;
      if (resource.json_params) {
        resources[0].json_params = resource.json_params;
      }
      this.currentArtistsjobitem.artists_jobs_resources.push(resources[0]);
      this.resValue.next(this.currentArtistsjobitem.artists_jobs_resources);
      this.gql.job(this.job.id).subscribe(
        job => {
          this.job = this.utils.deepCopyByValue(job.data.artists_jobs);
          this.setJob();
          if (this.assetAdjustmentsService.exportedQuery) {
            this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url = this.utils.getCleanViewerURL(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url, this.assetAdjustmentsService.exportedQuery);
            this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url = this.utils.setUrlParam(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url, 'json-data', new Date().getTime().toString());
            this.preservedModelIndex = this.currentModelIndex;
            this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].update_json_params = true;
            if (resource.json_params)
              this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params = this.utils.getResourceJsonParams(resource.json_params, resource.resource_type);
            this.putResource(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex], this.refresh.bind(this, () => {
              this.onResourceUploaded.next(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]);
              this.assetAdjustmentsService.frameEventsAttached = false;
              this.assetAdjustmentsService.init(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url);
            }));
            delete this.assetAdjustmentsService.exportedQuery;
            this.assetAdjustmentsService.resetDefaults();
          } else
            this.onResourceUploaded.next(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]);
        }
      );
      this.pixels.sendPixel({
        event: 'savedNewResource',
        reference_id: this.job.id
      });
      this.warnings.forEach(rw => {
        rw.isActive = false;
      });
    };
    let file = null;
    if (resource.buffer) {
      file = new Blob([resource.buffer], { type: 'application/octet-stream' });
    } else {
      file = new Blob([this.utils.base64ToArrayBuffer(resource.file)], { type: 'application/octet-stream' });
    }

    const sub = this.resumableUpload.sourceFiles(file);
    this.exportProgress = 1;
    sub.subscribe((res: ResumableSubject) => {
      switch (res.state) {
        case ResumableState.FILE_PROGRESS: {
          this.exportProgress = res.object.progress;
          break;
        }
        case ResumableState.ERROR: {
          console.warn(res);
          console.warn('Something went wrong during upload of a specific file, ERROR . . .');
          this.openUploadErrorDialog({ error: 'Connection was interrupted, please try again.' });
          break;
        }
        case ResumableState.COMPLETE: {
          let payload = {
            uploaded_file_url: res.object.message,
            uploaded_file_name: 'scene.glb',
            job_item_id: this.currentArtistsjobitemId,
            archive: 'glb',
            cam_change: false,
            resource_type: 7, // 7 => GLB, 6 => glTF
            encrypt: true,
            compress: typeof this.compressOnFix === 'boolean' ? this.compressOnFix : resource.compress,
            mesh_compress: this.assetAdjustmentsService.meshCompress,
            org_scene: true,
            as_base64: true,
            source_resource_id: this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].id,
            quantize_texcoord_bits: resource.quantize_texcoord_bits,
            quantize_normal_bits: resource.quantize_normal_bits,
            poly_type: this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex] ? this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].poly_type : resource.poly_type,
            // as_zip: true,
            // as_gzip: true,
            compression_type: CompressionType.BROTLI,
            target_resource_type: resource.target_resource_type ? resource.target_resource_type : (this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex] ? this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].resource_type : null),
            use_hexa_naming: false,
            src_images_resource_id: resource.src_images_resource_id
          } as Resource;
          if (payload.poly_type) {
            const polySpecs = this.currentArtistsjobitem.artists_jobs_items_polygon_specifications.find(ps => ps.poly_type === payload.poly_type);
            if (polySpecs) {
              payload.target_poly_shape_type = polySpecs.poly_shape_type;
            }
          }
          delete this.compressOnFix;
          // payload.target_poly_shape_type = PolyShapeType.QUADRANGULAR;

          this.rest.afterResource('post', payload).subscribe(
            (res: Resource) => onSuccess(res),
            err => this.openUploadErrorDialog(err)
          );
          break;
        }
      }
    });
  }

  setArtistsjobitemFormats() {
    this.job.artists_jobs_items.forEach(aji => {
      aji.artists_jobs_items_resources_types = this.resourceTypesService.mapUiToResourceType(
        this.requiredFormats, this.allFormats, aji.artists_jobs_items_resources_types,
        {
          key: 'job_item_id',
          value: aji.id
        });
    });
  }

  managerChange(manager: User) {
    if (manager) {
      this.job.manager_id = manager.id;
      this.saveJob(() => {
        this.manager = manager;
      });
    }
  }

  qualitySupervisorChange(qualitySupervisor: User) {
    if (qualitySupervisor) {
      this.job.quality_supervisor = qualitySupervisor.id;
      this.saveJob(() => {
        this.qualitySupervisor = qualitySupervisor;
      });
    }
  }

  deleteFeedbacker() {
    delete this.feedbacker;
    delete this.job.feedbacker_id;
    this.saveJob();
  }

  feedbackerChange(feedbacker: User) {
    if (feedbacker) {
      this.job.feedbacker_id = feedbacker.id;
      this.saveJob(() => {
        this.feedbacker = feedbacker;
      });
    }
  }

  artistChange(artist: User) {
    if (!artist || !confirm('Are you sure you want to ' + (this.reAssign ? 're-assign' : 'duplicate') + ' this job?')) return;
    let job = this.job;
    let query = '';
    if (this.reAssign) {
      query = '/' + job.id;
    } else {
      job = Object.assign(this.job);
      job.source_job = this.job.id;
      delete job.id;
    }
    job.artists_users = [artist];
    job.artist_user_id = this.job.artists_users[0].id;
    this.rest.jobs(this.reAssign ? 'put' : 'post', job, query).subscribe((jobObj: Job) => {
      let data: Notification = {
        text: 'job ' + (this.reAssign ? 'assigned to ' : 'created under ') + artist.name,
        type: NotificationType.Success,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
      if (!this.reAssign)
        // location.href = 'job/' + (jobObj instanceof Array ? jobObj[0].id : jobObj.id);
        this.utils.forceRedirectTo('/job/' + (jobObj instanceof Array ? jobObj[0].id : jobObj.id));
    }, err => {
      const data: Notification = {
        text: err,
        type: NotificationType.Error,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
    });
  }

  changeFormats() {
    this.setArtistsjobitemFormats();
    this.setAllAvailableFormats();
    this.saveJob(this.refresh.bind(this));
  }

  setAllAvailableFormats() {
    this.allAvailableFormats = [];
    if (this.allFormats && this.requiredFormats && this.requiredFormats.length) {
      this.requiredFormats.forEach(i => this.allAvailableFormats.push(this.allFormats.find(f => f.id == i)))
    }
  }

  setFmActive(jobIds: Array<number>, fm_active: boolean) {
    if (!jobIds.length) {
      return;
    }
    if (!this.rolesHelper.isJobManager()) return;
    const payload = {
      values: {
        fm_active
      },
      where: {
        id: jobIds
      }
    };
    this.utils.observableToPromise(this.rest.jobs('put', payload));
  }

  async getJob(jobId = this.job.id): Promise<ApolloQueryResult<JobQueryData>> {
    return await this.utils.observableToPromise(this.gql.job(jobId));
  }

  async refresh(callback?: Function) {
    const job = await this.getJob();
    if (!this.job)
      this.job = this.utils.deepCopyByValue(job.data.artists_jobs);
    else
      this.utils.deepCopy(job.data.artists_jobs, this.job);
    this.setJob();
    this.counterComments++;
    if (callback) {
      callback();
    }
    this.onJobRefresh.next();
  }

  onPricingChange() {
    this.priceChanged = true;
  }

  saveJobAndRefresh() {
    this.saveJob(this.refresh.bind(this));
  }

  saveJob(callback?: Function): void {
    if (this.priceChanged && this.job.artists_jobs_pricing[0].base_price >= OfferService.MAX_OFFER_PRICE) {
      const code = prompt(`Maximum base price is ${OfferService.MAX_OFFER_PRICE - 1}$ while current price is ${this.job.artists_jobs_pricing[0].base_price}$. Either lower base price or insert Yehiel's code:`);
      if (code !== OfferService.MAX_OFFER_PRICE_CODE) {
        return;
      }
    }
    for (let i = 0; i < this.job.artists_jobs_subscribers.length; i++) {
      if (!this.utils.ValidateEmail(this.job.artists_jobs_subscribers[i].email)) {
        this.removeSubscribers(i--);
      }
    }
    this.saveSubscribers();
    this.job.items = [];
    this.productsToAdd.forEach(pid => this.job.items.push({ id: pid } as Product));
    if (this.job.pause_reason_id === -1) {
      this.job.pause_reason_id = null;
    }
    if (!this.job?.org_htc) {
      delete this.job.hours_to_complete;
    }
    this.rest.jobs('put', this.job, '/' + this.job.id).subscribe(job => {
      const data: Notification = {
        text: 'changes saved',
        type: NotificationType.Success,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
      if (this.job.is_archived) {
        this.chatService.historyDictionary = {} as { id: number, ChatHistory };
        this.chatService.refreshAndFetch(false);
      }
      if (callback) {
        callback();
      }
    }, err => {
      this.utils.httpErrorResponseHandler(err, 'failure saving changes');
    });
  }

  deleteJob({ id = this.job.id, doConfirm = true, onSuccess, allow_reopen = false, archive_reason }: {
    id?: number, doConfirm?: boolean, onSuccess?: Function, allow_reopen?: boolean, archive_reason: number
  }) {
    if (doConfirm && !confirm('Are you sure you want to delete this job forever?')) return;
    let query = `/${id}?allow_reopen=${allow_reopen}`;

    if (archive_reason) {
      query += `&archive_reason=${archive_reason}`;
    }

    this.rest.jobs('delete', null, query).subscribe({
      next: data => {
        if (onSuccess) {
          onSuccess();
        } else {
          this.router.navigate(['/jobs/list']);
        }

        this.chatService.refreshAndFetch(true);
      },
      error: err => {
        const data: Notification = {
          text: err,
          type: NotificationType.Error,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
      }
    });
  }

  openUploadErrorDialog(err: any) {
    this.dialog.open(FileUploadError, {
      width: (window.innerWidth - 20) + 'px',
      data: { err }
    });
    delete this.exportProgress;
  }

  sortResources(a: Resource, b: Resource) {
    const c = a.id || Number.MAX_SAFE_INTEGER || 9999990;
    const d = b.id || Number.MAX_SAFE_INTEGER || 9999990;
    return d - c;
  }

  async initResources() {
    return new Promise(async (resolve) => {
      if (!this.currentArtistsjobitem) {
        return;
      }
      if (!this.currentArtistsjobitem.artists_jobs_resources) {
        this.currentArtistsjobitem.artists_jobs_resources = [];
      }
      if (this.originalResources == null) {
        this.originalResources = Array.from(this.currentArtistsjobitem.artists_jobs_resources);
      }
      this.currentArtistsjobitem.artists_jobs_resources.sort(this.sortResources);
      this.feedbacks = this.mapper.getFeedbacksFromResources(this.originalResources);
      this.allFeedbacks = [];
      this.unresolvedFeedbacks = 0;
      if (this.job && this.job.artists_jobs_items) {
        this.job.artists_jobs_items.forEach(aji => {
          if (aji.artists_jobs_resources) {
            aji.artists_jobs_resources.forEach(ajr => {
              if (ajr.artists_resources_feedback) {
                ajr.artists_resources_feedback.forEach(f => {
                  this.allFeedbacks.push(f);
                  if (!f.fixed && !f.hidden) {
                    this.unresolvedFeedbacks++;
                  }
                });
              }
            });
          }
        });
      }
      const canSeeArchived = this.auth.user && this.roles.doesRolesHasPermission(this.auth.user.roles, 'View Archived Resources');
      if (!canSeeArchived) {
        for (let i = 0; i < this.currentArtistsjobitem.artists_jobs_resources.length; i++) {
          if (this.currentArtistsjobitem.artists_jobs_resources[i].is_archived)
            this.currentArtistsjobitem.artists_jobs_resources.splice(i--, 1);
        }
      }
      this.currentModelIndex = this.currentArtistsjobitem?.artists_jobs_resources?.length ? 0 : null;
      this.resourceDetails = null;
      this._normalizedDimensions = null;
      for (let i = 0; i < this.currentArtistsjobitem.artists_jobs_resources.length; i++) {
        if (!this.currentArtistsjobitem.artists_jobs_resources[i].is_archived) {
          this.currentModelIndex = i;
          break;
        }
      }
      if (typeof this.preservedModelIndex === 'number') {
        if (this.currentArtistsjobitem.artists_jobs_resources[this.preservedModelIndex]) {
          this.currentModelIndex = this.preservedModelIndex;
        }
      }
      if (typeof this.currentModelIndex === 'number') {
        this.setMediaTag();
        this.currentResource = this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex];
        if (this.mediaTag === MediaTag.MODEL) {
          await this.getResourceDetails(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]);
        }

        this.canReadyForReview = this.isReadyForReview(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex], false);
      } else {
        this.canReadyForReview = false;
      }
      const feedbacksForJobItem = this.mapper.getFeedbacksFromResources(this.currentArtistsjobitem.artists_jobs_resources);
      this.canApprove = !!!feedbacksForJobItem.filter(f => !f.fixed).length;
      this.setBlenderGlbFileSize();
      if (this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]) {
        this.setViewerUrl();
      }
      resolve(null);
    });
  }

  setViewerUrl() {
    const viewerUrlAny = this._viewerUrl as any;
    if (!viewerUrlAny || viewerUrlAny.changingThisBreaksApplicationSecurity !== this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url) {
      this._viewerUrl = this.safeWindowPipe.transform(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url);
      this.viewerCounter++;
    }
  }

  initSmallImagesUrls() {
    if (this.currentArtistsjobitem.artists_items[0].artists_items_data) {
      this.currentArtistsjobitem.artists_items[0].smallImagesUrls = [];
      this.currentArtistsjobitem.artists_items[0].artists_items_data.forEach(data => {
        this.currentArtistsjobitem.artists_items[0].smallImagesUrls.push(data.small_image_url);
      });
    }
  }

  async getResourceDetails(resource: Resource) {
    return new Promise(async (resolve, reject) => {
      if (resource && resource.id) {
        const query = '/' + resource.id;
        this.rest.resourceDetails('get', null, query).subscribe(
          async data => {
            this.resourceDetails = data as ResourceDetails;
            const fileSize = this.resourceDetails?.original_files?.total;
            if (fileSize) {
              this.resourceDetails.isBiggerThanLimit = this.isResourceBiggerThanLimit(fileSize);
            }
            this._normalizedDimensions = this.getNormalizedDimensions();
            this.resetWarningsRunning();
            await this.setWarnings();
            resolve(null);
          }
        )
      }
    });
  }

  convertToCM(dim: ResourceDimensions): ResourceDimensions {
    return this.utils.convertToCM(dim);
  }

  getActualDimensions(convertToCM = false): ResourceDimensions {
    if (!this.resourceDetails) {
      return null;
    }
    const res = {
      width: this.resourceDetails.width,
      height: this.resourceDetails.height,
      length: this.resourceDetails.length,
      units: this.resourceDetails.units
    } as ResourceDimensions;
    if (convertToCM) {
      return this.convertToCM(res);
    }
    return res;
  }

  getTargetlDimensions(convertToCM = false): ResourceDimensions {
    if (!this.currentArtistsjobitem.artists_items[0]) {
      return null;
    }
    const res = {
      width: this.currentArtistsjobitem.artists_items[0].width,
      height: this.currentArtistsjobitem.artists_items[0].height,
      length: this.currentArtistsjobitem.artists_items[0].length,
      units: this.currentArtistsjobitem.artists_items[0].units
    } as ResourceDimensions;
    if (convertToCM) {
      return this.convertToCM(res);
    }
    return res;
  }

  getNormalizedDimensions(actual = this.getActualDimensions(true), target = this.getTargetlDimensions(true)): ResourceDimensions {
    if (target && actual && actual.units === target.units) {
      const ratio = this.getRatio(actual, target);
      const res = this.utils.deepCopyByValue(actual);
      res.width *= ratio;
      res.height *= ratio;
      res.length *= ratio;
      return res;
    }
    return null;
  }

  getRatio(actual = this.getActualDimensions(true), target = this.getTargetlDimensions(true)): number {
    if (target && actual && actual.units === target.units) {
      const maxTarget = this.getMaxDim(target);
      const maxActual = this.getMaxDim(actual);
      return maxTarget / maxActual;
    }
  }

  getRetailerID() {
    let rid = null;
    if (this.job.artists_jobs_items[0] && this.job.artists_jobs_items[0].artists_items[0] && this.job.artists_jobs_items[0].artists_items[0].products[0].retailers) {
      rid = this.getRID();
    }
    return rid;
  }

  async removeAllEmissive(rw?: ResourceWarning) {
    if (rw) {
      rw.isActive = true;
    }
    if (!this.assetAdjustmentsService.isViewerReady) {
      await this.assetAdjustmentsService.broadcastViewerFullyLoaded();
    }
    const fixId = this.sendFixEvent(FixStage.TRY_TO_FIX, rw?.desc);
    if (!this.assetAdjustmentsService.materials) {
      await this.assetAdjustmentsService.getMaterials();
    }
    if (this.assetAdjustmentsService.materials) {
      this.assetAdjustmentsService.materials.forEach(m => {
        if (m.emissiveMap) {
          this.assetAdjustmentsService.deleteTexture(m.name, 'emissiveMap');
        }
      });
      await this.utils.setTimeout();
      this.assetAdjustmentsService.export({
        useOptipng: true
      });
      this.sendFixEvent(FixStage.FIXED, rw.desc, fixId);
    } else {
      rw.isActive = false;
      this.sendFixEvent(FixStage.NOT_FIXED, rw.desc, fixId);
    }
  }

  async setWarnings() {
    if (!this.job || this.resourceDetails?.isBiggerThanLimit) return; // in case caller is feedback or any other "external" (or independent) component
    if (!this.assetAdjustmentsService.isViewerReady && this.mediaTag == this.MODEL) return false;
    if (this._isWarningsRunning) {
      this._execWarningsWhenDone = true;
      return;
    }
    this._isWarningsRunning = true;
    const sort = () => {
      this.warnings.sort((a: ResourceWarning, b: ResourceWarning) => {
        return b.level - a.level;
      });
    };
    const isGeometry = !!this.job.artists_jobs_types.find(ajt => ajt.type_id === JobsTypes.GEOMETRY);
    const isTexture = !!this.job.artists_jobs_types.find(ajt => ajt.type_id === JobsTypes.TEXTURE);
    let warnings = [] as Array<ResourceWarning>;
    // We might mot get aftet this line in case viewer hasn't been fully loaded
    if (this.assetAdjustmentsService.isViewerReady && this.mediaTag == this.MODEL) {
      this.assetAdjustmentsService.getNumOfTransparentMaterials().then((res) => {
        const numOfTransparents = res;
        if (numOfTransparents > JobService.MAX_TRANSPARENTS && !this.warnings.find(w => w.type === ResourceWarningType.TransparentMaterial)) {
          this.warnings.push({
            type: ResourceWarningType.TransparentMaterial,
            desc: `Model contains ${numOfTransparents} transparent materials, please try to define them as opaque if possible`,
            level: ResourceWarningLevel.Warning,
            // videoSrc: 'https://www.youtube.com/embed/B-qAXBK71u0'
          } as ResourceWarning);
          sort();
        }
      });
    }
    this.setTotalTexturesSize();
    this.setTotalUVSize();
    const actual = this.getActualDimensions(true);
    const target = this.getTargetlDimensions(true);
    warnings = warnings.concat(this.isDimensionsValid(actual, target));
    // if (this._numOfMultipleUV) {
    //   warnings.push({
    //     type: ResourceWarningType.NumOfMultipleUV,
    //     desc: `model contains ${this._numOfMultipleUV} geometries with more than 1 UV set`,
    //     level: this.isWanna() ? ResourceWarningLevel.Error : ResourceWarningLevel.Warning,
    //     action: this.fixMultipleUV.bind(this)
    //   } as ResourceWarning);
    // }
    if (!this.assetAdjustmentsService.meshesData && this.assetAdjustmentsService.isViewerReady)
      await this.assetAdjustmentsService.getMeshesData();
    if (this.assetAdjustmentsService.meshesData) {
      if (isTexture) {
        let uvValid = true;
        for (let i in this.assetAdjustmentsService.meshesData) {
          if (this.assetAdjustmentsService.meshesData[i].numOfUVs > 1) {
            uvValid = false;
            break;
          }
        }
        if (!uvValid) {
          warnings.push({
            type: ResourceWarningType.NumOfMultipleUV,
            desc: `model contains geometries with more than 1 UV set`,
            // level: this.isWanna() ? ResourceWarningLevel.Error : ResourceWarningLevel.Warning,
            level: ResourceWarningLevel.Warning,
            // action: this.fixMultipleUV.bind(this)
          } as ResourceWarning);
        }
      }
      const numOfMeshes = Object.keys(this.assetAdjustmentsService.meshesData).length;
      if (this.isAmazon() && isTexture) {
        if (!this.assetAdjustmentsService.materials)
          await this.assetAdjustmentsService.getMaterials();
        if (this.assetAdjustmentsService.materials) {
          let munOfTransparent = 0, hasEmissive = false;
          this.assetAdjustmentsService.materials.forEach(m => {
            if (m.transparent)
              munOfTransparent++;
            if (m.emissiveMap)
              hasEmissive = true;
          });
          if (hasEmissive) {
            warnings.push({
              type: ResourceWarningType.MapNotAllowed,
              desc: `This model is using emissive map while according to this job's guide this is not allowed. Please remove this map. All lights should be turned off.`,
              level: ResourceWarningLevel.Error,
              action: this.removeAllEmissive.bind(this)
              // videoSrc: 'https://www.youtube.com/embed/RmcoE60QYZo'
            } as ResourceWarning);
          }
          if (munOfTransparent > 0) {
            if (munOfTransparent > 1 || numOfMeshes != 2)
              warnings.push({
                type: ResourceWarningType.NumOfMeshes,
                desc: `This model has transparent materials, thus should have 2 meshes, 1 for opaque material and the other for transparent material.`,
                level: ResourceWarningLevel.Error,
                // videoSrc: 'https://www.youtube.com/embed/RmcoE60QYZo'
              } as ResourceWarning);
          } else {
            if (this.assetAdjustmentsService.materials.length > 1 || numOfMeshes > 1) {
              warnings.push({
                type: ResourceWarningType.NumOfMeshes,
                desc: `This model has no transparent materials, thus should have a single mesh and material.`,
                level: ResourceWarningLevel.Error,
                // videoSrc: 'https://www.youtube.com/embed/RmcoE60QYZo'
              } as ResourceWarning);
            }
          }
        }
        const textures = await this.assetAdjustmentsService.broadcastTextures(true, false);
        let tSizeValid = true, mimeValid = true, tTypeValid = true;
        const requiaredTextures = ['aoMap', 'map', 'metalnessMap', 'normalMap', 'roughnessMap'];
        textures.forEach(a => {
          let typeCounter = 0;
          for (let i in a) {
            const t = a[i];
            if (t.imageWidth && (t.imageWidth != 4096 || t.imageHeight != 4096)) {
              tSizeValid = false;
            }
            if (t.mimeType) {
              if (t.mimeType != 'image/png')
                mimeValid = false;
            }
            else if ((t as any).userData?.mimeType) {
              if ((t as any).userData.mimeType != 'image/png')
                mimeValid = false;
            }
            typeCounter += requiaredTextures.find((n) => n === i) ? 1 : 0;
          }
          if (typeCounter < requiaredTextures.length)
            tTypeValid = false;
        });
        if (!tTypeValid) {
          warnings.push({
            type: ResourceWarningType.TextureRequiaredTypes,
            desc: 'Please ensure to always include one material map of occlusion, roughness, metallic, normal and diffuse.',
            level: ResourceWarningLevel.Error
          });
        }
        if (!tSizeValid) {
          warnings.push({
            type: ResourceWarningType.TextureResolution,
            desc: 'Please ensure the texture maps are in 4k resolution (4096X4096) before uploading.',
            level: ResourceWarningLevel.Error
          } as ResourceWarning);
        }
        if (!mimeValid) {
          warnings.push({
            type: ResourceWarningType.TextureFormat,
            desc: 'Texture maps must be in PNG format. Other formats are not supported.',
            level: ResourceWarningLevel.Error
          } as ResourceWarning);
        }
        if ((this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].resource_type === FormatsType.glTF ||
          this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].resource_type === FormatsType.GLB) &&
          Object.keys(this.resourceDetails.original_files).filter(k => k.toLowerCase().indexOf('_diffuse') > -1).length > 1) {
          warnings.push({
            type: ResourceWarningType.SingleUVSet,
            desc: 'All materials should use the same UV and the same textures set.',
            level: ResourceWarningLevel.Error,
            videoSrc: 'https://www.youtube.com/embed/sJ-FHxnYvzo'
          } as ResourceWarning);
        }
      } else if (numOfMeshes > JobService.MAX_MESHES)
        warnings.push({
          type: ResourceWarningType.NumOfMeshes,
          desc: `Maximum number of meshes is ${JobService.MAX_MESHES} while your model has ${numOfMeshes} meshes, please merge meshes in a reasonable way`,
          level: ResourceWarningLevel.Error,
          videoSrc: 'https://www.youtube.com/embed/RmcoE60QYZo'
        } as ResourceWarning);
      if (isGeometry) {
        if (target && actual && actual.units == target.units) {
          let ratio = this.getRatio(actual, target);
          actual.width *= ratio;
          actual.height *= ratio;
          actual.length *= ratio;
          let mmmActual = this.utils.getMinMidMax(actual);
          let mmmTarget = this.utils.getMinMidMax(target);
          let min = mmmActual.min.value / mmmTarget.min.value;
          if (min > 1)
            min = mmmTarget.min.value / mmmActual.min.value;
          let mid = mmmActual.mid.value / mmmTarget.mid.value;
          if (mid > 1)
            mid = mmmTarget.mid.value / mmmActual.mid.value;
          let finalP = 100 - (Math.min(min, mid) * 100);
          // Exclude some sub categories from proportions errors, should be removed after we will support Proportions / Dimensions per sub-category
          const checkProportions = () => {
            // Unity:
            // this.getRetailerID() !== 840
            let res = true;
            if (this.currentArtistsjobitem.artists_items[0].artists_items_sub_categories_associations[0] &&
              this.currentArtistsjobitem.artists_items[0].artists_items_sub_categories_associations[0].sub_category_id)
              if (environment.ignoreGeometryProportionsSubCat.find(s => s == this.currentArtistsjobitem.artists_items[0].artists_items_sub_categories_associations[0].sub_category_id))
                res = false;
            return res;
          };

          if (finalP > 3 && checkProportions()) {
            warnings.push({
              type: ResourceWarningType.Proportions,
              desc: `Mesh proportions (X vs Y vs Z) has a ${(finalP).toFixed(1)}% deviation, please make sure model dimensions are correct`,
              level: (finalP > 10 && !this.job.bypass_dimensions) ? ResourceWarningLevel.Error : ResourceWarningLevel.Warning,
              videoSrc: 'https://www.youtube.com/embed/fPJW2aY8Ba8'
              // action: () => this.router.navigateByUrl('/jobs/alignment/' + this.job.id + '/' + this.currentArtistsjobitem.id + '/' + this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].id)
            } as ResourceWarning);
          }
          this.assetAdjustmentsService.setNormalizeScale(ratio);
        }
      }
    }
    if (this.currentArtistsjobitem && this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]) {
      if (this.hasUnfixedResource()) {
        warnings.push({
          type: ResourceWarningType.FixFeedbacks,
          desc: 'Please mark all feedbacks as Fixed after attending issues',
          level: ResourceWarningLevel.Error,
          videoSrc: 'https://www.youtube.com/embed/1Vq7WT_u-KQ'
        } as ResourceWarning);
      }
      if (!this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].artists_jobs_resources_source_files || !this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].artists_jobs_resources_source_files.length) {
        if (this.job.status === 'Pending Source Files') {
          const isSourceFileMatch = this.job.source_file_match;
          const desc = isSourceFileMatch === false ?
            'One of your source files does not match the approved files, please upload new source files to get paid' :
            'Upload SOURCE FILES to get paid';
          warnings.push({
            type: ResourceWarningType.MissingSourceFiles,
            desc,
            level: ResourceWarningLevel.Error,
            action: this.openUploadWizard.bind(this, {
              initStep: UploadWizardStep.SOURCE_TYPE,
              extra: { desc }
            } as UploadWizardOptions),
            videoSrc: 'https://www.youtube.com/embed/67mJR91gBDc'
          } as ResourceWarning);
        }
      }
      if (this.currentArtistsjobitem.UI && this.resourceDetails && this.resourceDetails.poly_count && this.currentArtistsjobitem.artists_jobs_resources) {
        const poly = this.currentArtistsjobitem.UI.polyTypes.find(i => i.key == this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].poly_type);
        const extractedPolyShapeType = this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].extracted_poly_shape_type;
        if (isGeometry) {
          if (poly && poly.value && typeof poly.value.max === 'number') {
            if (poly.value.max < this.resourceDetails.poly_count) {
              warnings.push({
                type: ResourceWarningType.PolyCount,
                desc: `Maximum polygon count is ${this.decimalPipe.transform(poly.value.max)} while model polygon count is ${this.decimalPipe.transform(this.resourceDetails.poly_count)}. Please reduce polygons.`,
                level: ResourceWarningLevel.Error,
                videoSrc: 'https://www.youtube.com/embed/5YyOEV66ilM'
              } as ResourceWarning);
            } else if (poly.value.min > this.resourceDetails.poly_count) {
              warnings.push({
                type: ResourceWarningType.PolyCount,
                desc: `Minimum polygon count is ${this.decimalPipe.transform(poly.value.min)} while model polygon count is ${this.decimalPipe.transform(this.resourceDetails.poly_count)}. Please add more polygons.`,
                level: ResourceWarningLevel.Warning
              } as ResourceWarning);
            }
            if (extractedPolyShapeType && poly.value.shapeType != extractedPolyShapeType && !isTexture) {
              warnings.push({
                type: ResourceWarningType.PolyShape,
                desc: `Uploaded polygon shape is ${this.polyShapeTypesDictionary[extractedPolyShapeType].value.name}, please provide ${poly.value.shapeName} instead.`,
                level: ResourceWarningLevel.Error,
                action: this.fixPolygonShape.bind(this)
              } as ResourceWarning);
            }
          }
        }
        if (isTexture && this.assetAdjustmentsService.shadowPlane) { // && this.currentResource.poly_type != PolyType.HIGH
          if (this.assetAdjustmentsService.shadowPlane.active && !this.assetAdjustmentsService.shadowPlane.physical && !this.assetAdjustmentsService.shadowPlane.reflector && await this.assetAdjustmentsService.hasRedundantTextures()) {
            warnings.push({
              type: ResourceWarningType.RedundantTextures,
              desc: 'Some lights are casting shadow while no mesh is receiving shadow',
              level: ResourceWarningLevel.Error,
              action: this.fixRedundantTextures.bind(this),
              videoSrc: 'https://www.youtube.com/embed/wfL6Wbag8Yg'
            });
          }
          if (this.resourceDetails && this.resourceDetails.original_files) {
            const addFileSizeWarning = (level: ResourceWarningLevel) => {
              let action = null;
              const originalFilesArr = Object.keys(this.resourceDetails.original_files);
              if (this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex] && !this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].mesh_compress) {
                action = this.fixFileSize.bind(this);
              } else if (!originalFilesArr.find(i => i.toLowerCase().indexOf('.jpg') > -1 || i.toLowerCase().indexOf('.jpeg') > -1)) {
                action = this.fixFileSize.bind(this);
              } else if (this.getBinSize() > JobService.MAX_BIN_SIZE) {
                action = this.fixFileSize.bind(this);
              }
              warnings.push({
                type: ResourceWarningType.FileSize,
                desc: 'Total images size is ' + size.toFixed(1) + ' MB, please reduce Textures size',
                level,
                action
              });
            }
            const size = this.totalTexturesSize;
            if (poly && poly.value && poly.value.maxSize) {
              if (size > poly.value.maxSize) {
                addFileSizeWarning(this.getMaxGlbSize() <= JobService.MAX_GLB_SIZE ? ResourceWarningLevel.Error : ResourceWarningLevel.Warning);
              } else if (size > JobService.MAX_VIEWER_FILE_SIZE_2 && this.isAdmin) {
                addFileSizeWarning(ResourceWarningLevel.Warning);
              }
            } else if (size > JobService.MAX_VIEWER_FILE_SIZE_1) {
              addFileSizeWarning(this.getMaxGlbSize() <= JobService.MAX_GLB_SIZE && poly.key !== PolyType.HIGH ? ResourceWarningLevel.Error : ResourceWarningLevel.Warning);
            } else if (size > JobService.MAX_VIEWER_FILE_SIZE_2 && this.isAdmin) {
              addFileSizeWarning(ResourceWarningLevel.Warning);
            }

          }
        }
      }
      if (this.currentArtistsjobitem.artists_jobs_items_resources_types && !this.currentArtistsjobitem.artists_jobs_items_resources_types.find(rt => rt.resource_type_id === this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].resource_type)) {
        const formats = this.requiredFormats.map(r => this.allFormats.find(f => f.id === r)).map(r => r.resource_name);
        warnings.push({
          type: ResourceWarningType.FileTypeMismatch,
          desc: `File type ${this.allFormats.find(f => f.id === this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].resource_type).resource_name} is not required for this job. Please upload one of those formats: ${formats.toString()}`,
          level: ResourceWarningLevel.Error
        } as ResourceWarning);
      }
    }
    if (this.assetAdjustmentsService.UVsOverlapCount) {
      warnings.push({
        type: ResourceWarningType.UVsOverlap,
        desc: `Model has ${this.decimalPipe.transform(this.assetAdjustmentsService.UVsOverlapCount)} overlapping UVs`,
        level: ResourceWarningLevel.Warning
      } as ResourceWarning);
    }
    if (this.currentArtistsjobitem.artists_jobs_items_polygon_specifications && this.currentArtistsjobitem.artists_jobs_items_polygon_specifications.length > 1
      && this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]?.artist_approved) {
      const uploadedPolyTypes = {} as any;
      this.currentArtistsjobitem.artists_jobs_resources.filter(aji => !aji.is_archived).forEach(r => {
        uploadedPolyTypes[r.poly_type] = true;
      });
      if (Object.keys(uploadedPolyTypes).length < this.currentArtistsjobitem.artists_jobs_items_polygon_specifications.length) {
        let missingPoly = null as ArtistsJobsItemsPolygonpecification;
        this.currentArtistsjobitem.artists_jobs_items_polygon_specifications.forEach(pt => {
          if (!uploadedPolyTypes[pt.poly_type]) {
            missingPoly = pt;
          }
        });
        if (missingPoly) {
          warnings.push({
            type: ResourceWarningType.PolyTypeMissing,
            desc: `Please upload a ${PolyType[missingPoly.poly_type]} POLY model`,
            level: ResourceWarningLevel.Warning
          } as ResourceWarning);
        }
      }
    }
    if (this.overlapMeshesNames.length) {
      warnings.push({
        type: ResourceWarningType.OverlapMeshesNames,
        desc: `Some meshes are using the same names (${this.overlapMeshesNames.map(p => p.key).toString()})`,
        level: ResourceWarningLevel.Error,
        action: this.fixOverlapMeshesNames.bind(this)
      } as ResourceWarning);
    }
    if (this.negativeScaleCount) {
      warnings.push({
        type: ResourceWarningType.NegativeMeshScale,
        desc: `${this.negativeScaleCount} mesh(es) are using negative scale, Please use positive scale axis values only`,
        level: ResourceWarningLevel.Error,
        videoSrc: 'https://www.youtube.com/embed/RzhGxGrhShs'
      } as ResourceWarning);
    }
    if (this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex] && this.mediaTag == MediaTag.MODEL &&
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].artists_jobs_resources_alignments) {
      const videoSrc = 'https://www.youtube.com/embed/jziC32B642w',
        desc = `Please create an alignment screenshot in order to validate the model fidelity`;
      let missingAlignments: ResourceWarning;
      if (!this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].artists_jobs_resources_alignments.length) {
        const ug = await this.getUserGroups();
        let level = isGeometry ? ResourceWarningLevel.Error : ResourceWarningLevel.Warning;
        if (ug.find(g => g.type === GroupType.FULL_TIMER)) {
          level = ResourceWarningLevel.Error;
        }
        missingAlignments = {
          type: ResourceWarningType.MissingAlignments,
          desc,
          level,
          videoSrc,
          action: this.fixMissingAlignments.bind(this)
        } as ResourceWarning;
      }
      if (this.rolesHelper.isArtistAdminLogedin()) {
        if (!missingAlignments) {
          missingAlignments = {
            type: ResourceWarningType.MissingAlignments,
            desc,
            level: ResourceWarningLevel.Warning,
            videoSrc,
            action: this.fixMissingAlignments.bind(this)
          } as ResourceWarning;
        }
        const userHasAlignment = this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].artists_jobs_resources_alignments.find(a => a.artist_user_id === this.auth.user.id);
        if (this.job.artist_user_id !== this.auth.user.id && this.job.id % 2 === 0 && !userHasAlignment) {
          missingAlignments.level = ResourceWarningLevel.Error;
        }
        if (userHasAlignment) {
          missingAlignments = null;
        }
      }
      if (missingAlignments) {
        // Don't force alignment for rank 1 artists
        if (this.job.artist_user_id == this.auth.user.id) {
          if (this.auth.user.rank <= 1)
            missingAlignments.level = ResourceWarningLevel.Warning;
        }
        warnings.push(missingAlignments);
      }
    }
    // Check for geometry accuracy
    if (isGeometry && this.mediaTag === MediaTag.MODEL &&
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex] &&
      !this.isAccuracyTestValid()) {
      const showError = this.job.bypass_geometry_accuracy ? false : true;
      // let showError = this.job.artist_user_id === this.auth.user.id;
      // if (this.job.bypass_geometry_accuracy)
      //   showError = false;
      const level = showError ? ResourceWarningLevel.Error : ResourceWarningLevel.Warning;
      const desc = showError ? 'Please conduct and pass a geometry accuracy test' : 'You can conduct an accuracy test, to verify your model’s geometry';

      warnings.push({
        type: ResourceWarningType.MissingGeometryAccuracy,
        desc,
        level,
        buttonText: 'take test',
        action: this.fixMissingGeometryAccuracy.bind(this),
        videoSrc: 'https://www.youtube.com/embed/N-AjjQVmts8?si=jviedBqyqGvndwrJ&amp;start=243'
      } as ResourceWarning);
    }
    // For Amazon's diffuse validation
    if (isTexture && this.assetAdjustmentsService.isViewerReady && this.mediaTag === this.MODEL && this.isAmazon()) {
      // if (isTexture && this.assetAdjustmentsService.isViewerReady && this.mediaTag == this.MODEL) {


      let maxVal;
      let minVal;
      const min = JobService.DIFFUSE_MIN_THRESHOLD;
      const max = JobService.DIFFUSE_MAX_THRESHOLD;
      if (
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]?.diffuse_max_value === null ||
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]?.diffuse_min_value === null
      ) {
        let materials = await this.assetAdjustmentsService.broadcastDiffuseThreshold();

        maxVal = 0;
        minVal = 255;
        for (const name in materials) {
          for (const p in materials[name]) {
            if (p.indexOf('max') > -1) {
              maxVal = Math.max(maxVal, materials[name][p]);
            }
            if (p.indexOf('min') > -1) {
              minVal = Math.min(minVal, materials[name][p]);
            }
          }
        }

        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].diffuse_max_value = maxVal;
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].diffuse_min_value = minVal;
        this.putResource(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]);
      } else {
        maxVal = this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]?.diffuse_max_value;
        minVal = this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]?.diffuse_min_value;
      }
      if (maxVal > max || minVal < min) {
        warnings.push({
          type: ResourceWarningType.DiffuseThreshold,
          desc: `Some materials contain diffuse (color) maps with pixels that are too bright or dark`,
          level: ResourceWarningLevel.Error,
          action: this.fixDiffuseThreshold.bind(this)
        });
      }
    }
    // Check for color comparison
    if (isTexture && this.mediaTag === this.MODEL &&
      !this.job.bypass_color_comparison &&
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex] &&
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].artists_jobs_resources_color_comparisons &&
      !this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].artists_jobs_resources_color_comparisons
        .find(cc => this.isColorComparisonValid(cc))) {
      const showError = this.job.artist_user_id === this.auth.user.id; // && !this.isMixtiles();
      const level = showError ? ResourceWarningLevel.Error : ResourceWarningLevel.Warning;
      const desc = showError ? 'Please conduct and pass a color sample test' :
        'You can conduct a color sample test to verify your model’s color values';
      warnings.push({
        type: ResourceWarningType.MissingColorComparison,
        desc,
        level,
        buttonText: 'take test',
        action: this.fixMissingColorComparison.bind(this)
      });
    }
    if (isTexture && this.mediaTag === this.MODEL &&
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]) {
      if (this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].uv_out_of_bounds)
        warnings.push({
          type: ResourceWarningType.UvOutOfBounds,
          desc: 'UV mapping out of bounds, please check your UV boundaries',
          level: ResourceWarningLevel.Error
        });
      if (this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].uv_overlaps)
        warnings.push({
          type: ResourceWarningType.UVsOverlap,
          desc: `Model has overlapping UVs`,
          level: ResourceWarningLevel.Warning
        } as ResourceWarning);
    }
    if (isTexture && this.isWannaShoes()) {
      const textures = await this.assetAdjustmentsService.broadcastTextures(true, false);
      textures.forEach(a => {
        for (let i in a) {
          const t = a[i];
          if (!warnings.some(w => w.type === ResourceWarningType.WannaShoes) && t.imageWidth && (t.imageWidth > 1024 || t.imageHeight > 1024)) {
            warnings.push({
              type: ResourceWarningType.WannaShoes,
              desc: `Please ensure the texture maps are in 1k resolution before uploading.`,
              level: ResourceWarningLevel.Error
            } as ResourceWarning);
            this.canReadyForReview = false;
          }
        }
      });
    }
    this.warnings = warnings;
    sort();
    this._isWarningsRunning = false;
    // TODO starting pivot is different then 0,0,0
    this.canReadyForReview = this.isReadyForReview(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex], false);
    if (this._execWarningsWhenDone) {
      this._execWarningsWhenDone = false;
      this.setWarnings();
    }
  }

  resetWarningsRunning() {
    this._execWarningsWhenDone = false;
    this._isWarningsRunning = false;
  }

  isColorComparisonValid(cc: ArtistsJobsResourcesColorComparison) {
    return cc.comparison_delta_ab <= AB_THRESHOLD && cc.comparison_delta_l <= LIGHTNESS_THRESHOLD;
  }

  isAccuracyTestValid() {
    let valid = false;
    const isValid = (gas: Array<ArtistsJobsResourcesGeometryAccuracy>) => !!gas?.find(ga => ga.score >= JobService.MIN_GEOMETRY_ACCURACY_SCORE);
    // if (this.job.same_mesh) {
    //   this.job.artists_jobs_items.forEach(aji => {
    //     aji.artists_jobs_resources.forEach(r => {
    //       valid = valid || isValid(r.artists_jobs_resources_geometry_accuracy);
    //     });
    //   });
    // } else {
    //   valid = isValid(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].artists_jobs_resources_geometry_accuracy);
    // }
    valid = isValid(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].artists_jobs_resources_geometry_accuracy);
    return valid;
  }

  getUserGroups(): Promise<Array<Group>> {
    return new Promise(async (resolve, reject) => {
      if (this._artistGroups) {
        resolve(this._artistGroups);
      } else {
        this.onArtistGroupsFetch.push(resolve);
        if (this.onArtistGroupsFetch.length === 1) {
          this._artistGroups = await this.auth.getUserGroups(this.job.artist_user_id);
          this.onArtistGroupsFetch.forEach(f => f(this._artistGroups));
          this.onArtistGroupsFetch = [];
        }
      }
    });
  }

  getTotalTexturesSize(finalGlbSize?: number, resourceDetails = this.resourceDetails): number {
    if (resourceDetails) {
      // get the final GLB size
      finalGlbSize = finalGlbSize || this.getGlbSize();
      // get the original (same as final but without draco compression) geometry
      const totalGeometry = this.utils.getTotalImagesSize(resourceDetails.original_files, ['.bin', '.gltf']);
      // texture size is GLB size reduced by the geometry size
      return finalGlbSize - totalGeometry;
    }
  }

  getSourceResource(aji: ArtistJobItem): Resource {
    return aji.artists_jobs_resources.find(r => r.resource_type === FormatsType.OBJ);
  }

  getTotalUVSize(finalGlbSize: number, texturesSize: number, GeoGlbSize: number): number {
    return finalGlbSize - (texturesSize + GeoGlbSize);
  }

  setTotalUVSize() {
    if (this.resourceDetails && (this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex] &&
      (this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].resource_type === FormatsType.GLB ||
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].resource_type === FormatsType.glTF))) {
      const sourceResource = this.getSourceResource(this.currentArtistsjobitem);
      if (sourceResource?.blender_glb_file_size) {
        this._totalUVSize = this.getTotalUVSize(this.finalGlbSize, this.totalTexturesSize, sourceResource.blender_glb_file_size);
      } else {
        this._totalUVSize = null;
      }
    } else {
      this._totalUVSize = null;
    }
  }

  setTotalTexturesSize() {
    if (this.resourceDetails && (this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex] &&
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].resource_type === FormatsType.GLB ||
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].resource_type === FormatsType.glTF)) {
      this.finalGlbSize = this.getGlbSize();
      this._totalTexturesSize = this.getTotalTexturesSize(this.finalGlbSize);
      if (this.oldTotalTexturesSize) {
        const obj: Notification = {
          text: `texture files size reduced by ${-(100 - (100 * (this.totalTexturesSize / this.oldTotalTexturesSize))).toFixed(2)}%`,
          type: NotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', obj);
        delete this.oldTotalTexturesSize;
      }
    } else {
      this._totalTexturesSize = null;
    }
  }

  hasUnfixedResource(): boolean {
    for (let i = 0; i < this.currentArtistsjobitem.artists_jobs_resources.length; i++) {
      if (this.currentArtistsjobitem.artists_jobs_resources[i].artists_resources_feedback) {
        for (let j = 0; j < this.currentArtistsjobitem.artists_jobs_resources[i].artists_resources_feedback.length; j++) {
          if (!this.currentArtistsjobitem.artists_jobs_resources[i].artists_resources_feedback[j].fixed) {
            return true;
          }
        }
      }
    }
    return false;
  }

  isReadyForReview(resource: Resource, revert: boolean): boolean {
    if (!resource) {
      return false;
    }

    if (!this.assetAdjustmentsService.isViewerReady && this.mediaTag === this.MODEL) {
      return false;
    }

    if (this._isWarningsRunning || this.hasUnfixedResource() ||
      this.warnings.filter(w => w.level === ResourceWarningLevel.Error).length ||
      !this.currentArtistsjobitem.artists_jobs_items_resources_types ||
      !this.currentArtistsjobitem.artists_jobs_items_resources_types.find(rt => rt.resource_type_id == resource.resource_type)) {
      if (revert) {
        setTimeout(() => {
          this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].artist_approved = false;
        }, 0);
      }
      return false;
    }
    return true;
  }

  initCategories(categories: Array<Category>) {
    const catDictionaries = this.categoriesService.getDictionaries(categories);
    this.categoriesDictionary = catDictionaries.categoriesDictionary;
    this.subCategoriesDictionary = catDictionaries.subCategoriesDictionary;
  }

  isToUSDZConvertible() {
    return (this.isAdmin) && this.currentArtistsjobitem &&
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex] &&
      (this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].resource_type === 7 ||
        this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].resource_type === 6)
  }

  refreshAudit() {
    this.gql.jobAudit(this.job.id).subscribe(
      log => {
        this.auditLog = this.utils.deepCopyByValue(log.data.artists_jobs_audit);
        this.calcBallCourt();
      }
    )
  }

  calcBallCourt() {
    if (!this.auditLog || !this.auditLog.length) {
      this.ballCourt = [];
      return;
    }
    if (!this.job) {
      return;
    }

    const now = this.utils.getHoursFromMS(new Date().getTime());
    const jobCreatedAt = this.utils.getHoursFromMS(this.utils.getSafeDate(this.job.created_at).getTime());
    let totalHours = now - jobCreatedAt;
    const auditLog = this.utils.deepCopyByValue(this.auditLog.filter(i => i.field_name === 'status' || i.field_name === 'countdown_active')) as Array<JobAudit>;
    auditLog.map(i => i.created_at = this.utils.getSafeDate(i.created_at));
    auditLog.sort((a: JobAudit, b: JobAudit) => {
      if (a.created_at < b.created_at) {
        return -1;
      } else if (a.created_at > b.created_at) {
        return 1;
      } else {
        return 0;
      }
    });
    const approvedAudit = auditLog.filter(i => i.new_value === 'Approved Job');
    if (approvedAudit.length) {
      let lastRelevant = approvedAudit[approvedAudit.length - 1];
      if (auditLog[auditLog.length - 1].field_name === 'status' && auditLog[auditLog.length - 1].new_value !== 'Approved Job') {
        lastRelevant = auditLog[auditLog.length - 1];
      }
      this.endDate = lastRelevant.created_at;
      totalHours = this.utils.getHoursFromMS(lastRelevant.created_at.getTime()) - jobCreatedAt;
    }
    this.ballCourt = [{
      hours: auditLog.length ? 0 : totalHours,
      isArtistSide: true,
      percentage: auditLog.length ? 0 : 100
    }];
    let sumHours = 0;
    let isLastArtistSide = true;
    let lastChangedAt = jobCreatedAt;
    let lastCountdownActive = true;
    for (let i = 0; i < auditLog.length; i++) {

      const handleStatus = (forceArtistSide: boolean) => {
        if (isLastArtistSide !== this.isArtistStatus(auditLog[i].new_value, lastCountdownActive) || forceArtistSide) {
          const changedAt = this.utils.getHoursFromMS(auditLog[i].created_at.getTime());
          // this.ballCourt[this.ballCourt.length - 1].hours = totalHours - (changedAt - jobCreatedAt);
          this.ballCourt[this.ballCourt.length - 1].hours = changedAt - lastChangedAt;
          this.ballCourt[this.ballCourt.length - 1].percentage = (this.ballCourt[this.ballCourt.length - 1].hours / totalHours * 100);
          sumHours += this.ballCourt[this.ballCourt.length - 1].hours;
          lastChangedAt = changedAt;
          isLastArtistSide = this.isArtistStatus(auditLog[i].new_value, lastCountdownActive);
          const nextBallCourt = {
            isArtistSide: isLastArtistSide || forceArtistSide
          } as BallCourt;
          this.ballCourt.push(nextBallCourt);

          return auditLog[i].new_value === 'Approved Job';
        }
      }
      if (auditLog[i].field_name === 'countdown_active') {
        lastCountdownActive = auditLog[i].new_value === 'true';
        if (i === auditLog.length - 1 && lastCountdownActive) {
          if (handleStatus(true)) {
            break;
          }
        }
      } else {
        if (handleStatus(false)) {
          break;
        }
      }
    }
    // if (typeof this.ballCourt[this.ballCourt.length - 1].hours !== 'number') { // handle last one in case there's more then one
    this.ballCourt[this.ballCourt.length - 1].hours = totalHours - sumHours;
    this.ballCourt[this.ballCourt.length - 1].percentage = (this.ballCourt[this.ballCourt.length - 1].hours / totalHours * 100);
    // }
    this.artistPercentage = 0;
    this.clientPercentage = 0;
    this.artistHours = 0;
    this.clientHours = 0;
    this.ballCourt.forEach(bc => {
      if (bc.isArtistSide) {
        this.artistPercentage += bc.percentage;
        this.artistHours += bc.hours;
      } else {
        this.clientPercentage += bc.percentage;
        this.clientHours += bc.hours;
      }
    });
    this.refreshBonus();
    this.setTimerPause();
  }

  setTimerPause() {
    var user = this.job?.artists_users && this.job?.artists_users[0];
    this.timerPause = this.job.is_paused || this.rolesHelper.doesUserHasRole(user, "Artist Without Time Limit") || !this.isArtistStatus(this.job.status, this.job.countdown_active); // || (this.job.status == 'Job Has Feedback' && !this.job.countdown_active);
  }

  fixHoursToComplete(job: Job, setGauge = false) {
    if (typeof job.hours_to_complete !== 'number') {
      job.hours_to_complete = 0;
    }
    if (typeof job.counter_updated_at !== 'object' && typeof job.counter_updated_at !== 'number') {
      if (typeof job.counter_updated_at === 'string') {
        job.counter_updated_at = this.utils.getSafeDate(job.counter_updated_at);
      } else {
        job.counter_updated_at = new Date();
      }
    }

    if (this.isArtistStatus(job.status, job.countdown_active) && !job.uiInit && !job.is_paused) {
      job.hours_to_complete -= this.utils.getTimespan(job.counter_updated_at, new Date()) / 3600000;
    }

    if (setGauge) {
      this.flatGaugeFrom = this.utils.getSafeDate(this.job.created_at);
      this.flatGaugeTo = new Date();
      const hours = Math.floor(this.job.hours_to_complete);
      const minutes = (this.job.hours_to_complete - hours) * 60;
      this.flatGaugeTo.setHours(this.flatGaugeTo.getHours() + hours);
      this.flatGaugeTo.setMinutes(this.flatGaugeTo.getMinutes() + minutes);
    }
  }

  isArtistStatus(status: string, countdownActive: boolean): boolean {
    return status === 'In Progress' || (status === 'Job Has Feedback' && countdownActive);
  }

  isMultiple(item: Job): boolean {
    let res = item.artists_jobs_items.length > 1;
    if (item.same_mesh && item.artists_jobs_types && item.artists_jobs_types.find(i => i.type_id === JobsTypes.GEOMETRY) && !this.isAdmin) {
      res = false;
    }
    return res;
  }

  onModelIndex() {
    this.warnings = [];
    // this._numOfMultipleUV = 0;
    this.overlapMeshesNames = [];
    this.negativeScaleCount = 0;
    this.setMediaTag();
    this.preservedModelIndex = this.currentModelIndex;
    if (typeof this.currentModelIndex === 'number') {
      if (this.mediaTag === MediaTag.MODEL) {
        this.getResourceDetails(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]);
      }
      this.currentResource = this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex]
      this.setViewerUrl();
      this.setBlenderGlbFileSize();
    }
    delete this.assetAdjustmentsHelperService.currentPreset;
    this.detectChanges();
  }

  setBlenderGlbFileSize() {
    if (this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex] && this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].blender_glb_file_size) {
      this.blenderGlbFileSize = this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].blender_glb_file_size / (1024 * 1024);
    } else {
      delete this.blenderGlbFileSize;
    }
  }

  detectChanges() {
    this.broadcaster.broadcast('detectChanges');
  }

  getJobImagesById(id: number): Array<NativeResourceSet> {
    let images = [];
    if (this.job.artists_jobs_items) {
      if (this.job.UI.isMulti) {
        const currentItem = this.job.artists_jobs_items.filter(i => i.id === id)[0];
        if (currentItem) {
          images = this.mapper.getJobItemImages(currentItem);
        }
      } else {
        this.job.artists_jobs_items.forEach(i => {
          images = images.concat(this.mapper.getJobItemImages(i));
        })
      }
    }

    return images;
  }

  async onDimensions(dim: ResourceDimensions) {
    if (dim && dim.units && this.resourceDetails) {
      this.resourceDetails.units = dim.units;
      this.resourceDetails.width = dim.width;
      this.resourceDetails.height = dim.height;
      this.resourceDetails.length = dim.length;
    }
    await this.setWarnings();
  }

  async changeSrc(changes: AdjustmentsSourceChanges) {
    if (changes.state === AdjustmentsSourceChangesState.discard) {
      this.assetAdjustmentsService.init(changes.src);
      this.refresh();
    } else {
      const viewerUrl = this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url;
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url = changes.src;
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url = this.utils.setUrlParam(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url, 'json-data', new Date().getTime().toString());
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params = this.utils.getResourceJsonParams(changes.json_params, this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].resource_type);
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params = await this.assetAdjustmentsHelperService.onBeforeSave(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].json_params);
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].update_json_params = true;
      this.preservedModelIndex = this.currentModelIndex;
      this.putResource(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex], () => {
        this.assetAdjustmentsService.init(viewerUrl);
        const obj: Notification = {
          text: 'scene saved',
          type: NotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', obj);
        this.refresh();
      });
    }
  }

  putResourceAsync(resource: Resource) {
    return new Promise((resolve, reject) => {
      this.putResource(resource, resolve, reject);
    });
  }

  public putResource(resource: Resource, successCB?: Function, failureCB?: Function, sendToCms?: boolean): void {
    resource.viewer_url = this.utils.setUrlParam(resource.viewer_url, 'engine', null);
    this.rest.jobResources('put', resource, `/${resource.id}?send-to-cms=${!!sendToCms}`).subscribe({
      next: (updatedResource: Resource) => {
        for (let i = 0; i < this.currentArtistsjobitem.artists_jobs_resources.length; i++) {
          if (this.currentArtistsjobitem.artists_jobs_resources[i].id == resource.id)
            this.utils.deepCopy(resource, this.currentArtistsjobitem.artists_jobs_resources[i])
          // this.currentArtistsjobitem.artists_jobs_resources[i], resource;
        }
        // this.initResources();
        if (typeof successCB === 'function')
          successCB();
      },
      error: err => {
        this.utils.httpErrorResponseHandler(err, 'failure');
        if (typeof failureCB === 'function') {
          failureCB();
        }
      }
    });
  }

  setIsAdjustments() {
    this._isAdjustmentsOnDom = this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex] && this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url && this.adjustmentsMode == AdjustmentsMode.NORMAL;
  }

  toggleAdjustmentsMode(state: AdjustmentsMode) {
    delete this.adjustmentsMode;
    this.detectChanges();
    setTimeout(() => {
      this.adjustmentsMode = state;
      this.setIsAdjustments();
    });
  }

  saveNewFeedback(feedback: ArtistsResourcesFeedback, resource: Resource, job = this.job, callback?: Function) {
    if (feedback) {
      feedback.artist_user_id = job.artist_user_id;
      feedback.opened_by = this.auth.user.id;
      feedback.resource_id = resource.id;
      if (feedback.feedback_types)
        feedback.title = feedback.feedback_types[0].description;
      if (feedback.transcription) {
        feedback.transcription = JSON.stringify(feedback.transcription);
      }
      this.rest.jobFeedback('post', feedback).subscribe(
        (data: ArtistsResourcesFeedback) => {
          if (!(resource.artists_resources_feedback instanceof Array)) {
            resource.artists_resources_feedback = [];
          }
          resource.artists_resources_feedback.push(data);
          this.initResources();
          this.pixels.sendPixel({
            event: "addFeedback",
            reference_id: job.id
          });
          if (this.job) {
            this.refresh();
          }
          feedback.id = data.id;
          if (callback) {
            callback(feedback);
          }
        },
        err => {
          this.utils.httpErrorResponseHandler(err, 'failure setting new password');
          resource.artists_resources_feedback.splice(resource.artists_resources_feedback.indexOf(feedback), 1);
        }
      )
    }
  }

  async specificationsReminderDialog(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const spect = this.artistsItemsSpecs.filter(s => s.mandatory);
      if (!spect.length) {
        resolve(true);
        return;
      }
      const d = this.dialog.open(SpecificationsReminderDialogComponent, {
        data: spect
      });
      d.afterClosed().subscribe((approved: boolean) => {
        resolve(approved);
      });
    });
  }

  async fixMultipleUV(rw?: ResourceWarning) {
    if (this.exportProgress || !this.assetAdjustmentsService.isViewerReady) {
      this.assetAdjustmentsService.postToChild('broadcastViewerFullyLoaded');
      const data: Notification = {
        text: 'model needs to load first',
        type: NotificationType.Info,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
      return;
    };
    if (rw) {
      rw.isActive = true;
    }
    this.sendFixEvent(FixStage.TRY_TO_FIX, rw?.desc)
    const options = {
      useOptipng: true
    } as GltfExportOptions;
    this.assetAdjustmentsService.export(options);
    this.cancelExportIfMUV = true;
  }

  async openUploadWizard(options?: UploadWizardOptions) {
    setTimeout(async () => {
      options = options || { initStep: null } as UploadWizardOptions;
      await this.specificationsReminderDialog();
      this.dialog.open(UploadWizardWrapComponent, {
        data: options
      });
    }, 500);
    this.body.scrollTo(window.scrollY, 0);
  }

  getChat(force = false) {
    if (this.job && (!this.gotChat || force)) {
      this.gotChat = true;
      this.chatService.openChatByJobId(this.job.id);
    }
  }

  async getJsonParamsByUrl(viewerUrl: string, orgResolve?: Function) {
    return new Promise((resolve, reject) => {
      const load = this.utils.getUrlParam(viewerUrl, 'load');
      const domain = this.utils.getDomain(viewerUrl);
      const jsonUrl = (`https://${domain}${load.substring(0, load.lastIndexOf('.'))}.json?v=${new Date().getTime()}`).toLowerCase();
      this.rest.get(jsonUrl).subscribe(
        json => {
          (orgResolve || resolve)(this.utils.safeParse(json));
        },
        () => {
          if (orgResolve) {
            reject();
          } else {
            setTimeout(() => {
              this.getJsonParamsByUrl(viewerUrl, resolve);
            }, 1000);
          }
        }
      )
    });
  }

  async resourceMerge(source_id: number, destination_id: number) {
    return new Promise((resolve, reject) => {
      this.rest.jobResourceMerge('post', { source_id, destination_id }).subscribe(
        json => {
          const obj: Notification = {
            text: 'resources Successfully merged',
            type: NotificationType.Success,
            action: 'OK'
          };
          this.broadcaster.broadcast('notifyUser', obj);
          resolve(null);
        },
        err => {
          this.utils.httpErrorResponseHandler(err, 'resource merge failed');
          reject();
        }
      );
    });
  }

  async duplicateLightsAdjustments() {
    const paramsArr = ['exp', 'plane', 'plane-c', 'dimensions', 'dimensions-c', 'le-spt-p', 'le-dir-p', 'le-spt-distance', 'le-spt-decay', 'le-dir-distance', 'le-spt-angle', 'le-dir-angle', 'shadow-type'] as Array<string>;
    const types = ['hem', 'dir', 'pnt', 'amb', 'spt'];
    types.forEach(t => {
      paramsArr.push(`le-${t}`);
      paramsArr.push(`le-${t}-c`);
      paramsArr.push(`le-${t}-hsl`);
    });
    this.duplicateAdjustments(paramsArr, true);
  }

  async duplicateHDRIAdjustments() {
    this.duplicateAdjustments(['hdr', 'hdr-intensity', 'hdr-blur', 'hdrbg', 'le-probe'], false);
  }

  async duplicateAdjustments(paramsArr: Array<string>, duplicateLights: boolean) {
    const that = this;

    class duplicateResource {
      private resource: Resource;
      private src: any;
      private srcLights: any;

      constructor(resource: Resource, src: any, lights: any) {
        this.resource = resource;
        this.src = src;
        this.srcLights = lights;
      }

      async exe() {
        let url = this.resource.viewer_url;
        paramsArr.forEach(j => {
          url = that.utils.setUrlParam(url, j, null);
          url = that.utils.setUrlParam(url, j, this.src[j]);
        });
        this.resource.viewer_url = url;
        this.resource.viewer_url = that.utils.setUrlParam(this.resource.viewer_url, 'json-data', new Date().getTime().toString());
        this.resource.update_json_params = true;
        if (duplicateLights) {
          let json = await that.getJsonParamsByUrl(this.resource.viewer_url) as any;
          if (json) {
            json.scene = json.scene || {};
            if (json.materialManipulations)
              json.scene.materialManipulations = json.materialManipulations;
            json.scene.lights = json.scene.lights || {};
            json.scene.lights = this.srcLights;
            this.resource.json_params = await that.assetAdjustmentsHelperService.onBeforeSave(json);
          }
        }
        that.putResource(this.resource);
      }
    };
    if (this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex] &&
      this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url) {
      let src = {};
      let json = {} as any;
      if (duplicateLights)
        json = await that.getJsonParamsByUrl(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url) || {} as any;
      json.scene = json.scene || {};
      paramsArr.forEach(i => {
        src[i] = this.utils.getUrlParam(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url, i);
      });
      this.job.artists_jobs_items.forEach(aji => {
        if (this.currentArtistsjobitem.id != aji.id) {
          aji.artists_jobs_resources.sort(this.sortResources);
          for (let i = 0; i < aji.artists_jobs_resources.length; i++) {
            if (!aji.artists_jobs_resources[i].archive) {
              new duplicateResource(aji.artists_jobs_resources[i], src, json.scene.lights).exe();
              break;
            }
          }
        }
      });
    }
  }

  getSubCatDefaults(item: Job): { [id: number]: CategoryDefaults } {
    if (!item) {
      return null;
    }

    const defaults = this.categoriesService.subCategoriesDefaultsDictionary;
    const subCategoryId = item.artists_jobs_items[0].artists_items[0] && item.artists_jobs_items[0].artists_items[0].artists_items_sub_categories_associations && item.artists_jobs_items[0].artists_items[0].artists_items_sub_categories_associations[0] && item.artists_jobs_items[0].artists_items[0].artists_items_sub_categories_associations[0].sub_category_id;
    // this.assetAdjustmentsHelperService.subCategoryId = subCategoryId;
    // this.assetAdjustmentsHelperService.retailerSubCategoryId = item.artists_jobs_items[0].artists_items[0] && item.artists_jobs_items[0].artists_items[0].artists_items_sub_categories_associations && item.artists_jobs_items[0].artists_items[0].products[0].retailer_sub_category_id;
    const res = [] as { [id: number]: CategoryDefaults };
    if (defaults[subCategoryId]) {
      for (const polyTypeDefaults of defaults[subCategoryId]) {
        if (polyTypeDefaults.value) {
          item.artists_jobs_types.forEach(aot => {
            const def = polyTypeDefaults.value.find(cd => cd.jobType == aot.type_id);
            res[polyTypeDefaults.polyType] = def;
          });
        }
      }
    }
    return res;
  }

  getArtistActiveJobs(artistUserId: number): Observable<ApolloQueryResult<JobsQueryData>> {
    const options = {
      limit: 100,
      offset: 0,
      status: UtilsService.ARTIST_ACTIVE_JOBS_STATS,
      artist_user_id: [artistUserId],
      is_archived: false
    } as JobsFilterOptions;
    return this.gql.getArtistActiveJobs(options);
  }

  openMeshLibrary() {
    this.dialog.open(MeshLibraryDialogComponent, {
      width: window.innerWidth < 1270 ? '90vw' : '1270px',
      data: {
        meshLibraries: this.meshLibraries,
        subCatdesc: this.subCatName
      } as MeshLibraryWrapper
    });
  }

  openMLTeaser() {
    const dialogRef = this.dialog.open(MeshLibraryTeaserComponent, {
      width: '550px'
    });
    dialogRef.afterClosed().subscribe((open: boolean) => {
      if (open) {
        this.openMeshLibrary();
      }
    });
  }

  onProductsChange(val: string) {
    if (!val) {
      return;
    }

    const num = parseInt(val);
    if (!isNaN(num)) {
      this.productsToAdd.push(num);
    }
  }

  onJobTagChange(val: string) {
    if (!val) {
      return;
    }

    const item = {} as ArtistJobTag;
    item.tag = val;
    this.addTagToJob(item);
  }

  onJobPredefinedTagChange(t: ArtistJobTag) {
    this.addTagToJob(t);
  }

  public removeTag(t: ArtistJobTag): void {
    const query = t.id.toString();
    this.rest.tags('delete', null, '/' + query).subscribe({
      next: () => {
        const indx = this.jobTags.indexOf(t);
        this.jobTags.splice(indx, 1);
      }, error: err => {
        const data: Notification = {
          text: err,
          type: NotificationType.Error,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
      }
    });
  }

  addTagToJob(item: ArtistJobTag) {
    let exist = false;
    if (this.job.artists_jobs_tags) {
      this.job.artists_jobs_tags.forEach(t => {
        if (t.tag === item.tag) {
          exist = true;
        }
      });
    }
    if (exist) {
      this.utils.notifyUser({ text: `${item.tag} already exist`, type: NotificationType.Error, action: 'O.K.' });
      return;
    }
    const query = '';
    const tagQuery = {
      "job_id": this.job.id,
      "tag": item.tag,
      "is_predefined": false
    };
    this.rest.tags('post', tagQuery, query).subscribe({
      next: (tagObj: ArtistJobTag) => {
        this.jobTags.push(tagObj);
      }, error: err => {
        const data: Notification = {
          text: err,
          type: NotificationType.Error,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
      }
    });
  }

  public removeProduct(index: number): void {
    this.productsToAdd.splice(index, 1);
  }

  public removePoly(i: number): void {
    if (!confirm('Are you sure you want to delete this poly type forever?')) return;
    const current = this.currentArtistsjobitem.artists_jobs_items_polygon_specifications[i];
    if (current?.id)
      this.rest.jobItemPolySpec('delete', null, `/${current.id}`).subscribe({
        next: () => this.refresh(),
        error: err => this.utils.httpErrorResponseHandler(err, 'failure updating poly specs')
        });
    else
      this.currentArtistsjobitem.artists_jobs_items_polygon_specifications.splice(i, 1);
  }

  savePoly() {
    if (this.currentArtistsjobitem.artists_jobs_items_polygon_specifications.some(
      p =>
        !p.poly_shape_type ||
        !p.poly_type ||
        !p.job_type ||
        !p.min_poly_count ||
        !p.max_poly_count)) {
      alert('You must fill mandatory fields');
    } else {
      this.currentArtistsjobitem.artists_jobs_items_polygon_specifications.forEach(
        (p: ArtistsJobsItemsPolygonpecification) => {
          if (p.variation_name === '')
            p.variation_name = null;
          const query = `${p.id ? '/' + p.id : ''}`
          if (!p?.id) {
            let aji = this.job.artists_jobs_items.filter(a => a.id === this.currentArtistsjobitemId);
            if (aji?.length) {
              p.job_item_id = aji[0].id;
            }
          }
          this.rest.jobItemPolySpec(p.id ? 'put' : 'post', p, query).subscribe({
            next: () => this.refresh(),
            error: err => {
              if (err && err.error && err.error.indexOf('duplicate key value violates unique constraint') > -1) {
                const n: Notification = {
                  text: 'Variation name already exists. Please enter a unique name!',
                  type: NotificationType.Error,
                  action: 'OK'
                }
                this.broadcaster.broadcast('notifyUser', n);
              } else {
                this.utils.httpErrorResponseHandler(err, 'failure updating poly specs')
              }
            }
          })
        }
      )
      this.pixels.sendPixel({
        event: 'job_change_poly',
        job_id: this.job.id,
        event_value: this.currentArtistsjobitem.artists_jobs_items_polygon_specifications[0].max_poly_count,
        previous_value: this.currentArtistsjobitem.UI.polyTypes[0].value.max
      });
    }
  }

  addPoly() {
    const newPoly = {
      min_poly_count: null,
      max_poly_count: null,
      poly_type: null,
      poly_shape_type: null,
      serial_number: null,
      variation_name: null,
      job_type: null
    };
    if (!this.currentArtistsjobitem?.artists_jobs_items_polygon_specifications)
      this.currentArtistsjobitem.artists_jobs_items_polygon_specifications = [];
    this.currentArtistsjobitem.artists_jobs_items_polygon_specifications.push(newPoly);

  }

  getGlbSize(resourceDetails = this.resourceDetails): number {
    const glbRaw = this.utils.getTotalImagesSize(resourceDetails.viewer_files, ['_raw.glb']);
    if (glbRaw) {
      return glbRaw;
    }

    const glbPlusArcore = this.utils.getTotalImagesSize(resourceDetails.viewer_files, ['glb'], ['_arcore_webp.glb', '_webp_arcore.glb', '_webp.glb', '_glb.zip']);
    const glbWithoutArcore = this.utils.getTotalImagesSize(resourceDetails.viewer_files, ['glb'], ['_arcore.glb', '_arcore_webp.glb', '_webp_arcore.glb', '_webp.glb', '_glb.zip']);
    if (glbPlusArcore === glbWithoutArcore) {
      return glbPlusArcore;
    }
    return glbPlusArcore - glbWithoutArcore;
  }

  getArCoreGlbUrl(): string {
    if (this.resourceDetails) {
      for (const i in this.resourceDetails.viewer_files) {
        if (i.indexOf('_arcore.glb') > -1) {
          const path = this.utils.getUrlParam(this.currentArtistsjobitem.artists_jobs_resources[this.currentModelIndex].viewer_url, 'load');
          const split = path.split('/');
          return `${this.endpoints.getEndpointDomain(EndPoints.THREE_JS_VIEWER)}${path.replace(`/${split[split.length - 1]}`, '')}/${i}`;
        }
      }
    }
  }

  statusChange(statusKey: string) {
    this.job.status = statusKey;
    if (this.job.pause_reason_id === -1) {
      this.job.pause_reason_id = null;
    }
    this.rest.jobs('put', this.job, '/' + this.job.id).subscribe(job => {
      const data: Notification = {
        text: 'Job status is ' + statusKey,
        type: NotificationType.Success,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
    }, err => {
      const data: Notification = {
        text: err,
        type: NotificationType.Error,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
    });
    this.pixels.sendPixel({
      event: 'manualStatusChange',
      job_id: this.job.id,
    });
  }

  getMaxGlbSize(job = this.job) {
    if (job && job.artists_jobs_items[0] && job.artists_jobs_items[0].artists_items[0] && job.artists_jobs_items[0].artists_items[0].products[0] && job.artists_jobs_items[0].artists_items[0].products[0].retailers && job.artists_jobs_items[0].artists_items[0].products[0].retailers[0] && typeof job.artists_jobs_items[0].artists_items[0].products[0].retailers[0].maximum_glb_size === 'number') {
      return job.artists_jobs_items[0].artists_items[0].products[0].retailers[0].maximum_glb_size / 1024 / 1024;
    }

    return JobService.MAX_GLB_SIZE;
  }

  async saveComment(comment: ArtistsJobsPrivateComments) {
    return new Promise((resolve, reject) => {
      const query = comment.id ? `/${comment.id}` : '';
      this.rest.jobComment(comment.id ? 'put' : 'post', comment, query).subscribe({
        next: () => this.refresh(resolve),
        error: err => this.utils.httpErrorResponseHandler(err, 'failure saving comment')
      });
    });
  }

  deleteComment(comment: ArtistsJobsPrivateComments) {
    const query = `/${comment.id}`;
    this.rest.jobComment('delete', null, query).subscribe({
      next: () => this.refresh(),
      error: err => this.utils.httpErrorResponseHandler(err, 'failure deleting comment')
    });
  }

  addSubscriber() {
    this.job.artists_jobs_subscribers.push({
      email: '',
      job_id: this.job.id
    } as ArtistsJobsSubscriber);
  }

  removeSubscribers(index: number) {
    const s = this.job.artists_jobs_subscribers[index];
    if (s.id) {
      const query = '/' + s.id;
      this.rest.jobSubscriber('delete', null, query).subscribe({
        next: () => {
          this.job.artists_jobs_subscribers.splice(index, 1);
        },
        error: err => this.utils.httpErrorResponseHandler(err, 'failure deleting subscriber')
      })
    } else {
      this.job.artists_jobs_subscribers.splice(index, 1);
    }
  }

  saveSubscribers() {
    this.job.artists_jobs_subscribers.forEach(s => {
      let query = '';
      if (s.id) {
        query = '/' + s.id;
      }
      this.rest.jobSubscriber(s.id ? 'put' : 'post', s, query).subscribe({
        next: () => { },
        error: err => this.utils.httpErrorResponseHandler(err, 'failure adding subscriber')
      })
    });
  }

  async validateUncheckReview(): Promise<Job> {
    return new Promise(async (resolve, reject) => {
      if (this.utils.isAboveTS(new Date().getTime(), this.utils.getSafeDate(this.job.updated_at).getTime(), JobService.MAX_MS_UNCHECK)) {
        resolve(null);
        return;
      }
      await this.auth.refreshUserDateAsync();
      const actives = await this.offerService.getArtistActiveJobs(this.auth.user.id);
      if (this.auth.user.job_capacity > actives.count) {
        resolve(null);
        return;
      }
      this.gql.job(actives.rows[0].id).subscribe(
        job => {
          const lastTakenJob = job.data.artists_jobs;
          let hasResource = false;
          lastTakenJob.artists_jobs_items.forEach(aji => {
            if (aji.artists_jobs_resources.length) {
              hasResource = true;
            }
          });
          if (hasResource) {
            resolve(null);
            return;
          }
          resolve(lastTakenJob);
        });
    });
  }

  isAligmentAB() {
    return this.job.UI.isGeometry;
  }

  getStickyTypes(): Array<KeyValueAnyPair> {
    const res = [] as Array<KeyValueAnyPair>;
    res.push({
      key: StickyType.NONE,
      value: null
    });
    res.push({
      key: StickyType.MONO,
      value: 0
    });
    res.push({
      key: StickyType.RETAIL,
      value: 1
    });
    res.push({
      key: StickyType.ARTIST_GROUP,
      value: 2
    });
    return res;
  }

  isResourceBiggerThanLimit(value) {
    if (value.toLowerCase().indexOf('mb') > -1) {
      const sizeArr = value.split(' ');
      if (!isNaN(sizeArr[0])) {
        return parseFloat(sizeArr[0]) >= JobService.ERRORS_MAX_FILE_SIZE_MB;
      }
    }
    return false;
  }

  async getPauseReasons(): Promise<Array<artists_pause_reasons>> {
    return new Promise((resolve, reject) => {
      if (!this.pausedReasons && !this.inProgress) {
        this.inProgress = true;
        this.gql.pauseReasons().subscribe({
          next: (res) => {
            this.inProgress = false;
            let notPaused = { id: -1, description: 'Not paused' } as artists_pause_reasons;
            let reasons = Object.assign([], res.data.pauseReasons);
            reasons.unshift(notPaused);
            this.pausedReasons = reasons;
            resolve(this.pausedReasons)
          },
          error: (err) => reject(err)
        })
      } else {
        resolve(this.pausedReasons);
      }
    });
  }

  async sendFirstFMJob() {
    if (this.rolesHelper.isFeedbackMaster()) {
      const options = {} as JobsFilterOptions;
      options.order_by = 'feedback_order';
      options.is_desc = true;
      options.is_archived = false;
      options.is_paused = false;
      options.status = ['Job Pending Review'];
      options.manager_id = [this.auth.user.id];
      const r = await this.utils.observableToPromise(this.gql.jobs(options)) as ApolloQueryResult<JobsQueryData>;
      const first = r.data.allJobs.rows[0];
      if (first) {
        this.pixels.sendPixel({
          event: 'qa_job_to_review',
          job_id: first.id
        });
      }
    }
  }

  OnDestroy() {
    if (this.subs.length) {
      this.subs.forEach(s => s.unsubscribe());
    }
    if (this.adjustmentsSubs.length) {
      this.adjustmentsSubs.forEach(s => s.unsubscribe());
    }
    delete this.job;
    delete this.originalResources;
    delete this.resourceDetails;
    delete this._artistGroups;
    this.onArtistGroupsFetch = [];
    this.warnings = [];
    this.auditLog = [];
    this.ballCourt = [];
    this.productsToAdd = [];
    this.onFetchingManager = [];
    this.onLightsSummary = [];
    this.onViewerFullyLoaded = [];
    this.jobImages = [];
    this.overlapMeshesNames = [];
    this.jobPredefinedTags = [];
    this._pausedReasonsFetching = [];
    this.negativeScaleCount = 0;
    delete this.pixelOnce;
    delete this.manager;
    delete this._fetchingManager;
    delete this.gotChat;
    delete this.meshLibraries;
    delete this.isFirstVisit;
    delete this.finalGlbSize;
    delete this.saveOnAutoAdjustScene;
    delete this.priceChanged;
    delete this._isAdjustmentsOnDom;
    delete this._isArtistUnderNDA;
    this.mlTeaserOpened = false;
    this.setItemUrl = false;
    // this._numOfMultipleUV = 0;
    this.resetWarningsRunning();
    this.assetAdjustmentsHelperService.onDestroy();
    if (this.onAdjustmentsInitSub) {
      this.onAdjustmentsInitSub.unsubscribe();
    }
    this.viewerCounter = 0;
    clearInterval(this.tickInterval);
  }

  resourcesAlignments(jobId) {
    return this.gql.resourcesAlignments(jobId);
  }
}
