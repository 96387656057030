/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ResumableState, ResumableSubject } from 'app/shared/resumable';
import { UtilsService } from 'app/shared/utils.service';
import { RestService } from 'app/communication/rest.service';
import { ResumableUploadService } from 'app/shared/resumable-upload.service';
import { AssetAdjustmentsService, GltfExportOptions, RenderingEngine, ResourceJsonParams, THREE_LATEST_VERSION } from 'asset-adjustments';
import { CompressionType, Resource } from 'app/job/resource';
import { PixelsService } from 'app/shared/pixels.service';
import { AuthService } from 'app/auth/auth.service';
import { BroadcasterService } from 'ng-broadcaster';
import { NotificationType, Notification } from 'app/shared/notifications';
import { FormatsType, KeyValueAnyPair } from 'app/shared/enums';
import { PreviewGeneratorFactory } from './preview-generator.factory';
import { MessagesHandlerService } from 'messages-handler';
import { AdjustmentsPreset, AdjustmentsPresetJson, AdjustmentsPresetOptions, ArtistsUsersPresetType, SAVE_PRESET_FOR, AdjustmentsData } from './asset-adjustments';
import { Subscription } from 'rxjs';
import { RetailerCategoriesService } from 'app/categories/retailer-categories.service';
import { CategoriesService } from 'app/categories/categories.service';
import { GraphqlService } from 'app/communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { JobQueryData } from 'app/job/job';
import { RolesHelperService } from 'app/auth/roles-helper.service';

@Injectable({
  providedIn: 'root'
})
export class AssetAdjustmentsHelperService {
  static ON_SCREEN_TYPES = ['obj', 'dae', 'ply', 'splat', 'ksplat'];
  static NO_AUTOADJUST_TYPES = ['ply', 'splat', 'ksplat'];
  public catchEmbed: boolean;
  public exportProgress: number;
  public shareState: boolean;
  public embedLink: string;
  public isViewerFullyLoaded: boolean;
  public currentPreset: AdjustmentsPreset;
  public presets: Array<AdjustmentsPreset>;
  public savePresetFor: SAVE_PRESET_FOR;
  public RETAILER_SUB_CATEGORY = SAVE_PRESET_FOR.RETAILER_SUB_CATEGORY;
  public PRODUCT_SUB_CATEGORY = SAVE_PRESET_FOR.PRODUCT_SUB_CATEGORY;
  public PERSONAL = SAVE_PRESET_FOR.PERSONAL;
  public RETAILER = SAVE_PRESET_FOR.RETAILER;
  public forceOffscreen: boolean;
  public forceNoAdjust: boolean;
  public noDamping: boolean;
  public modelOpacity = 1;
  public modelOpacityAnimation = false;
  private onAdjustmentMessageSubs: Array<Subscription>;
  private onAutoAdjustSceneResolve: Function;
  private onViewerFullyLoaded: Array<Function>;
  private onLightsSummary: Array<Function>;
  private _presetLoading: boolean;
  private _imagesDictionary = {} as { [id: string]: KeyValueAnyPair };

  constructor(
    private assetAdjustments: AssetAdjustmentsService,
    private assetAdjustmentsService: AssetAdjustmentsService,
    private utils: UtilsService,
    private rest: RestService,
    private resumableUpload: ResumableUploadService,
    private pixels: PixelsService,
    private auth: AuthService,
    private broadcaster: BroadcasterService,
    private mhService: MessagesHandlerService,
    private retailerCategoriesService: RetailerCategoriesService,
    private categoriesService: CategoriesService,
    private gql: GraphqlService,
    private rolesHelper: RolesHelperService
  ) {
    this.savePresetFor = this.PERSONAL;
    this.onAdjustmentMessageSubs = [];
    this.onViewerFullyLoaded = [];
    this.onLightsSummary = [];
    this._imagesDictionary = {};
  }

  get presetLoading() {
    return this._presetLoading;
  }

  async onResourceExport(obj: any) {
    if (!this.catchEmbed) return;
    let res = {
      archive: obj.type,
      file: obj.file,
      resource_type: FormatsType.GLB, // 7 => GLB, 6 => glTF
      encrypt: true,
      compress: false,
      mesh_compress: this.assetAdjustmentsService.meshCompress,
      as_base64: true,
      quantize_texcoord_bits: obj.quantizeTexcoordBits,
      quantize_normal_bits: obj.quantizeNormalBits,
      dracoCompressionLevel: obj.dracoCompressionLevel,
      // json_params: this.utils.getResourceJsonParams(obj.json_params, FormatsType.GLB),
      json_params: this.utils.getResourceJsonParams(await this.onBeforeSave(await this.assetAdjustmentsService.getJsonParamsAsync()), FormatsType.GLB),
      target_resource_type: obj.targetResourceType
    } as Resource;
    this.uploadNewResource(res);
    // this.meshCompress = false;
  }

  revealLink(link: string) {
    this.shareState = false;
    this.embedLink = link;
  }

  uploadNewResource(resource: Resource) {
    let onSuccess = (newResource: Resource) => {
      const aferLoaded = () => {
        let data: Notification = {
          text: 'New model has been successfully saved',
          type: NotificationType.Success,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
      };
      delete this.exportProgress;
      let params = this.utils.getParamsFromUrl(this.assetAdjustments.getCurrentSrc());
      if (this.utils.getRenderingEngine(newResource.viewer_url) === RenderingEngine.THREE)
        newResource.viewer_url = this.utils.setUrlParam(newResource.viewer_url, 'tv', THREE_LATEST_VERSION);
      newResource.viewer_url = this.utils.setUrlParam(newResource.viewer_url, 'json-data', new Date().getTime().toString());
      if (this.assetAdjustmentsService.exportedQuery) {
        newResource.viewer_url = this.utils.getCleanViewerURL(newResource.viewer_url, this.assetAdjustmentsService.exportedQuery);
        newResource.viewer_url = this.utils.getCleanViewerURL(newResource.viewer_url, params);
        newResource.viewer_url = this.utils.removeForbiddenViewerURL(newResource.viewer_url);
        // newResource.update_json_params = true;
        this.putResource(newResource, aferLoaded);
        delete this.assetAdjustmentsService.exportedQuery;
        this.assetAdjustmentsService.resetDefaults();
      }
      else {
        newResource.viewer_url = this.utils.getCleanViewerURL(resource.viewer_url, params);
        this.putResource(newResource, aferLoaded);
        // this.revealLink(resource.viewer_url);
      }
    };
    let file = null;
    if (resource.buffer)
      file = new Blob([resource.buffer], { type: 'application/octet-stream' });
    else
      file = new Blob([this.utils.base64ToArrayBuffer(resource.file)], { type: 'application/octet-stream' });

    let sub = this.resumableUpload.sourceFiles(file);
    this.exportProgress = 1;
    sub.subscribe(async (res: ResumableSubject) => {
      switch (res.state) {
        case ResumableState.FILE_PROGRESS: {
          this.exportProgress = res.object.progress;
          break;
        }
        case ResumableState.ERROR: {
          console.warn(res);
          console.warn('Something went wrong during upload of a specific file, ERROR . . .');
          let data: Notification = {
            text: 'Connection was interrupted, please try again.',
            type: NotificationType.Error,
            action: 'OK'
          }
          this.broadcaster.broadcast('notifyUser', data);
          break;
        }
        case ResumableState.COMPLETE: {
          let payload = {
            uploaded_file_url: res.object.message,
            uploaded_file_name: 'scene.glb',
            archive: 'glb',
            resource_type: 7, // 7 => GLB, 6 => glTF
            encrypt: true,
            compress: false,
            mesh_compress: this.assetAdjustmentsService.meshCompress,
            org_scene: true,
            as_base64: true,
            quantize_texcoord_bits: resource.quantize_texcoord_bits,
            quantize_normal_bits: resource.quantize_normal_bits,
            // as_zip: true,
            // as_gzip: true,
            compression_type: CompressionType.BROTLI,
            target_resource_type: 7,
            json_params: await this.onBeforeSave(resource.json_params),
            update_json_params: true
          } as Resource;

          this.rest.afterResource('post', payload).subscribe(
            (res: Array<Resource>) => {
              onSuccess(res[0]);
            },
            err => this.utils.httpErrorResponseHandler(err, 'failure uploading model')
          );
          break;
        }
      }
    });
  }

  async putResource(resource: Resource, successCB?: Function, failureCB?: Function) {
    resource.viewer_url = this.utils.setUrlParam(resource.viewer_url, 'engine', null);
    if (!resource.preview_image_url) {
      let data: Notification = {
        text: 'Model uploaded successfully, please stand by while creating a preview image . . .',
        type: NotificationType.Info,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
      let previewGeneratorFactory = new PreviewGeneratorFactory(this.mhService, this.utils, this.rest, 'cdn.creators3d.com');
      resource.preview_image_url = await previewGeneratorFactory.createPreview(resource);
    }
    this.rest.artistResource('put', resource, '/' + resource.id).subscribe(
      (resource: Resource) => {
        // for (let i = 0; i < this.currentArtistsjobitem.artists_jobs_resources.length; i++) {
        //   if (this.currentArtistsjobitem.artists_jobs_resources[i].id == resource.id)
        //     this.currentArtistsjobitem.artists_jobs_resources[i] = resource;
        // }
        // this.initResources();
        if (typeof successCB === 'function')
          successCB();
        this.revealLink(resource.viewer_url);
      },
      err => {
        this.utils.httpErrorResponseHandler(err, 'failure');
        if (typeof failureCB === 'function')
          failureCB();
      }
    );
  }

  public shareEmbed() {
    if (this.shareState) {
      return;
    }
    this.pixels.sendPixel({
      event: 'shareEmbed',
      reference_id: this.auth.user ? this.auth.user.id : null
    });
    if (this.auth.user) {
      this.shareState = true;
      this.catchEmbed = true;
      const options: GltfExportOptions = {
        downloadFile: false
      };
      this.assetAdjustmentsService.export(options);
    } else {
      const data: Notification = {
        text: 'Please login to upload and share the model',
        type: NotificationType.Info,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', data);
    }
  }

  initParams(oldSrc?: string) {
    this.assetAdjustmentsService.init(oldSrc);
    if (this.assetAdjustmentsService.parameters && this.assetAdjustmentsService.parameters.length) {
      const sw = this.assetAdjustmentsService.parameters.find(p => p.id === 'shaded-wireframe');
      if (sw) {
        // sw.color = BodyService.PRIMARY_COLOR;
        sw.color = '#000000';
        sw.colorChanged = true;
      }
    }
  }

  async applyPreset(src = this.assetAdjustmentsService.iframeModel.nativeElement.src): Promise<string> {
    return new Promise((resolve: any, reject: any) => {
      if (this.currentPreset) {
        this.applyPresetSrc(src);
        this.onLightsSummary.push(async () => {
          await this.assetAdjustmentsService.broadcastSceneSummary();
          if (this.currentPreset && this.currentPreset.preset_json) {
            if (this.currentPreset.preset_json.lights) {
              await this.assetAdjustmentsService.setLightsByJson(this.currentPreset.preset_json.lights);
            }
            if (this.currentPreset.preset_json.shadowPlane) {
              this.assetAdjustmentsService.shadowPlane = this.currentPreset.preset_json.shadowPlane;
              this.assetAdjustmentsService.applyShadowPlane();
            }
            if (this.currentPreset.preset_json.hdri) {
              if (!this.currentPreset.preset_json.hdri.type) {
                this.currentPreset.preset_json.hdri.intensity = 0;
              }
              this.assetAdjustmentsService.hdri = this.currentPreset.preset_json.hdri;
              this.assetAdjustmentsService.applyHDRI();
            }
            if (this.currentPreset.preset_json.params) {
              this.assetAdjustmentsService.params = this.currentPreset.preset_json.params;
              this.assetAdjustmentsService.applyParams();
            }
          }
          resolve();
        });
        this.listen();
        if (this.currentPreset.type_id === ArtistsUsersPresetType.PRIVATE) {
          this.savePresetFor = this.PERSONAL;
        } else {
          this.savePresetFor = this.currentPreset.retailer_sub_category_id ? this.RETAILER_SUB_CATEGORY : this.currentPreset.sub_category_id ? this.PRODUCT_SUB_CATEGORY : this.RETAILER;
        }
        this.onAutoAdjustSceneResolve = resolve;
      }
    });
  }

  applyPresetSrc(src = this.assetAdjustmentsService.iframeModel.nativeElement.src) {
    let params = this.currentPreset.preset_json.urlParats;
    for (let i in params) {
      src = this.utils.setUrlParam(src, i, params[i]);
    }
    const oldSrc = this.assetAdjustmentsService.iframeModel.nativeElement.src;
    this.assetAdjustmentsService.iframeModel.nativeElement.src = src;
    this.initParams(oldSrc);
    this.assetAdjustmentsService.applyUrlParams();
  }

  listen() {
    this.onAdjustmentMessageSubs.push(this.assetAdjustmentsService.broadcaster.subscribe(this.onAdjustmentMessage.bind(this)));
  }

  async onAdjustmentMessage(message: any) {
    // console.log('AssetAdjustmentsHelperService', message.key);
    switch (message.key) {
      case 'onLightsSummary': {
        // After loading a preset we should setLightsByJson and unsubscribe
        // this.assetAdjustmentsService.applyUrlParams();
        // this.applyPresetSrc();
        // setTimeout(() => { this.assetAdjustmentsService.setLightsByJson(this.currentPreset.preset_json.lights); }, 1000);
        // this.assetAdjustmentsService.init();
        // await this.assetAdjustmentsService.refreshLightsSummary();
        this.onLightsSummary.forEach(f => f());
        this.onLightsSummary = [];
        // this.onAutoAdjustSceneResolve = () => {
        //   // TODO fix this timeout
        //   setTimeout(() => {
        //     this.assetAdjustmentsService.applyUrlParams();
        //   }, 1000);
        // }
        break;
      }
      case 'onAutoAdjustScene': {
        // TODO fix this timeout
        setTimeout(() => {
          this.assetAdjustmentsService.applyUrlParams();
          if (this.onAutoAdjustSceneResolve) {
            this.onAutoAdjustSceneResolve(this.assetAdjustmentsService.iframeModel.nativeElement.src);
            delete this.onAutoAdjustSceneResolve;
          }
        }, 1000);
        this.onAdjustmentMessageSubs.forEach(s => s.unsubscribe());
        break;
      }
      case 'viewerFullyLoaded': {
        this.onViewerFullyLoaded.forEach(f => f());
        this.onViewerFullyLoaded = [];
      }
    }
  }

  onNewPresetChange(name: string, adjustmentsData: AdjustmentsData) {
    if (!name) {
      this.utils.notifyUser({
        text: 'Enter preset name first',
        type: NotificationType.Error
      })
      return;
    }

    if (!this.presets) {
      this.presets = [];
    }

    this.currentPreset = {
      artist_user_id: this.auth.user.id,
      preset_name: name,
      type_id: this.savePresetFor === SAVE_PRESET_FOR.PERSONAL ? ArtistsUsersPresetType.PRIVATE : ArtistsUsersPresetType.PUBLIC
    };

    if (this.currentPreset.type_id !== ArtistsUsersPresetType.PRIVATE) {
      if (this.savePresetFor === SAVE_PRESET_FOR.PRODUCT_SUB_CATEGORY) {
        this.currentPreset.sub_category_id = adjustmentsData.subCategoryId;
      } else if (this.savePresetFor === SAVE_PRESET_FOR.RETAILER_SUB_CATEGORY) {
        this.currentPreset.retailer_sub_category_id = adjustmentsData.retailerSubCategoryId;
      } else {
        this.currentPreset.retailer_id = adjustmentsData.retailerId;
      }
    }

    this.presets.push(this.currentPreset);
  }

  async getPresetJSON(): Promise<AdjustmentsPresetJson> {
    return new Promise(async (resolve: any, reject: any) => {
      const res = {} as AdjustmentsPresetJson;
      this.assetAdjustmentsService.recoverMuteLights();
      let src = this.assetAdjustmentsService.getCurrentSrc();
      src = this.utils.setUrlParam(src, 'load', null);
      // src = this.utils.setUrlParam(src, 'tv', null);
      src = this.utils.setUrlParam(src, 'zip', null);
      src = this.utils.setUrlParam(src, 'gzip', null);
      src = this.utils.setUrlParam(src, 'br', null);
      src = this.utils.setUrlParam(src, 'decrypt', null);
      src = this.utils.setUrlParam(src, 'autorotate', null);
      src = this.utils.setUrlParam(src, 'webp', null);
      src = this.utils.setUrlParam(src, 'compressed', null);
      src = this.utils.setUrlParam(src, 'json-data', null);
      src = this.utils.setUrlParam(src, 'config', null);
      res.urlParats = this.utils.getParamsFromUrl(src);
      const jp = await this.assetAdjustmentsService.getJsonParamsAsync();
      res.lights = jp.scene.lights;
      res.shadowPlane = jp.scene.shadowPlane;
      res.hdri = jp.scene.hdri;
      res.params = jp.scene.params;
      resolve(res);
    });
  }

  async saveCurrentPreset(adjustmentsData: AdjustmentsData) {
    if (!this.currentPreset) {
      this.utils.notifyUser({
        text: 'Please select an exsting preset or define a new one',
        type: NotificationType.Error
      })
      return;
    }
    if (this.auth.user && this.currentPreset.artist_user_id != this.auth.user.id) {
      this.utils.notifyUser({
        text: `You are unauthorized to edit someone else's preset`,
        type: NotificationType.Error
      })
      return;
    }
    this._presetLoading = true;
    this.currentPreset.preset_json = await this.getPresetJSON();
    if (this.currentPreset.preset_json.hdri && !this.currentPreset.preset_json.hdri.type) {
      this.currentPreset.preset_json.hdri.intensity = 0;
    }
    const query = this.currentPreset.id ? `/${this.currentPreset.id}` : '';
    if (!this.rolesHelper.isSULogedin()) {
      this.savePresetFor = this.PERSONAL;
    }
    this.currentPreset.type_id = this.savePresetFor === this.PERSONAL ? ArtistsUsersPresetType.PRIVATE : ArtistsUsersPresetType.PUBLIC;
    if (this.savePresetFor === this.RETAILER_SUB_CATEGORY) {
      this.currentPreset.retailer_sub_category_id = adjustmentsData.retailerSubCategoryId;
    } else {
      this.currentPreset.retailer_sub_category_id = null;
    }
    if (this.savePresetFor === this.PRODUCT_SUB_CATEGORY) {
      this.currentPreset.sub_category_id = adjustmentsData.subCategoryId;
    } else {
      this.currentPreset.sub_category_id = null;
    }
    if (this.savePresetFor === this.RETAILER) {
      this.currentPreset.retailer_id = adjustmentsData.retailerId;
    } else {
      this.currentPreset.retailer_id = null;
    }

    const method = this.currentPreset.id ? 'put' : 'post';
    this.rest.adjustmentsPresets(method, this.currentPreset, query).subscribe({
      next: (preset: AdjustmentsPreset) => {
        this.auth.updateAdjustmetnsPreset(true);
        this.currentPreset.id = preset.id;
        if (this.currentPreset.type_id !== ArtistsUsersPresetType.PRIVATE) {
          this.retailerCategoriesService.getSubCategories(true);
          this.categoriesService.getFullCategoriesAsync(true);
        }
      },
      error: err => this.utils.httpErrorResponseHandler(err, 'failure saving preset'),
      complete: () => this._presetLoading = false
    });
  }

  deleteCurrentPreset() {
    if (this.currentPreset) {
      if (this.currentPreset.id) {
        this._presetLoading = true;
        let query = `/${this.currentPreset.id}`;
        this.rest.adjustmentsPresets('delete', null, query).subscribe(
          preset => {
            this.auth.updateAdjustmetnsPreset(false);
            delete this.currentPreset;
          },
          err => this.utils.httpErrorResponseHandler(err, 'failure saving preset'),
          () => this._presetLoading = false
        );
      }
      else {
        for (let i = 0; i < this.auth.user.artists_users_presets.length; i++) {
          if (this.auth.user.artists_users_presets[i].preset_name == this.currentPreset.preset_name) {
            this.auth.user.artists_users_presets.splice(i, 1);
            break;
          }
        }
      }
    }
  }

  async getRetailerPresets(jobId: number): Promise<Array<AdjustmentsPreset>> {
    const presets = await this.utils.observableToPromise(this.gql.jobRetailerPresets(jobId)) as ApolloQueryResult<JobQueryData>;
    return this.utils.deepCopyByValue(presets.data.artists_jobs.artists_jobs_items[0].artists_items[0].products[0].retailers[0].artists_users_presets);
  }

  async getPresets(options: AdjustmentsPresetOptions): Promise<Array<AdjustmentsPreset>> {
    return new Promise(async (resolve: any, reject: any) => {
      let res: AdjustmentsPreset[] = [];

      // First we will add the retailer sub category presets
      if (options.retailer_sub_category_id) {
        const retailerSubCategories = await this.retailerCategoriesService.getSubCategories(true);
        const sc = retailerSubCategories.find(rsc => rsc.id === options.retailer_sub_category_id);
        if (sc && sc.artists_users_presets && sc.artists_users_presets.length) {
          res = res.concat(this.utils.deepCopyByValue(sc.artists_users_presets));
        }
      }

      // Next we will add the retailer presets
      if (options.retailer_id) {
        try {
          const retailerPresets = await this.getRetailerPresets(options.job_id);
          if (retailerPresets.length) {
            res = res.concat(retailerPresets);
          }
        } catch (err) {
          reject(err);
        }
        if (options.isAmazon) {
          const preset_json = {
            urlParats: {
              tv: THREE_LATEST_VERSION,
              bv: '6.6.0',
              engine: '2'
            },
            lights: {
              HemisphericLight: [
                {
                intensity:1,
                color: '#000000',
                position: {
                  x: 0,
                  y: 0,
                  z: 0
                },
                alias: 'HemisphericLight',
                followCamera: false,
                index: 0,
                nickname: 'hemispheric 1',
                type: 'HemisphericLight'
                },
                {
                intensity: 1,
                color: '#000000',
                position:{
                  x: 0,
                  y: 0,
                  z : 0
                },
                alias: 'HemisphericLight',
                followCamera: false,
                index: 1,
                nickname: 'hemispheric 2',
                type: 'HemisphericLight',
                }
              ]
            },
            shadowPlane: {
              color: '#000000',
              opacity: 0.18,
              active: false,
              physical: true,
              reflector: false,
              side: false,
              physicalOptions: {
                blur:0.5,
                darkness: null,
                opacity: 0,
                dim: 512,
                height: 0.1,
              }
            },
            hdri: {
              type: 0,
              background: false,
              blur: false,
              format: 'jpg',
              intensity: 0,
              lightProbe: 0,
              skybox: false,
              rotation: { x: 0, y: 0, z: 0 }
            },
            params: {
              exposure: 1,
              dimensions: false,
              dimensionsColor: null,
              meshId: false,
              physicallyCorrectLights: false,
              shadedWireframe: false,
              toneMapping: null,
              wireframeColor: null,
              dimensionsValues: {
                x: null,
                y: null,
                z: null,
              },
              transparentRenderOrder: null,
              fov: 15,
              wireframe: false,
              grid: false,
              shadedWireframeColor: null,
              xray: false,
              uv: false,
              closeup: false,
              hideBottom: false,
              matcap: false
            }
          } as AdjustmentsPresetJson;
          res = res.concat([{
            id: null,
            artist_user_id: null,
            preset_name: 'Amazon Ambient (hardcoded)',
            preset_json,
            type_id: 2,
            sub_category_id: null,
            retailer_sub_category_id: null,
            retailer_id: options.retailer_id,
            sort_index: -100
          }]);
        }
      }

      // Next we will add the product sub category presets
      if (options.sub_category_id) {
        await this.categoriesService.getFullCategoriesAsync(true);
        const hasPresetCategories = !!this.categoriesService.subCategoriesDictionary[options.sub_category_id]?.artists_users_presets?.length;
        if (hasPresetCategories) {
          res = res.concat(this.utils.deepCopyByValue(this.categoriesService.subCategoriesDictionary[options.sub_category_id].artists_users_presets));
        }
      }

      if (options.type_id === ArtistsUsersPresetType.PUBLIC) {
        if (this.auth.isloggedIn()) {
          await this.auth.updateAdjustmetnsPreset(true);
          res = res.concat(this.utils.deepCopyByValue(this.auth.user.artists_users_presets.filter(p => p.type_id === ArtistsUsersPresetType.PRIVATE)));
        }
      }

      res.sort((a, b) => a.sort_index - b.sort_index);
      res.sort((a, b) => (b.sub_category_id ? 1 : 0) - (a.sub_category_id ? 1 : 0));
      res.sort((a, b) => (b.retailer_sub_category_id ? 1 : 0) - (a.retailer_sub_category_id ? 1 : 0));

      resolve(res);
    });
  }

  sendFilesToViewer(files: Array<Blob | File>) {
    files.forEach(f => {
      if ((f as any).name) {
        AssetAdjustmentsHelperService.ON_SCREEN_TYPES.forEach(t => {
          if ((((f as any).name.indexOf(`.${t}`) > -1)))
            this.forceOffscreen = true;
        });
        AssetAdjustmentsHelperService.NO_AUTOADJUST_TYPES.forEach(t => {
          if ((((f as any).name.indexOf(`.${t}`) > -1)))
            this.forceNoAdjust = true;
        });
      }
    });
    this.broadcaster.broadcast('ViewerTesterComponent.initViewerUrl');
    if (this.isViewerFullyLoaded)
      this.assetAdjustmentsService.displayFiles(files);
    else {
      this.listen();
      this.onViewerFullyLoaded.push(() => {
        // setTimeout(() => {
        this.assetAdjustmentsService.displayFiles(files);
        // }, 1000);
      });
    }
    console.log(files);
  }

  // This method will replace the base64 images om the json_params to URL's but they will have a Creators3D domain so we cannot use it without a server side solution
  async onBeforeSave(params: ResourceJsonParams): Promise<ResourceJsonParams> {
    return new Promise(async (resolve: any, reject: any) => {
      // let imagesDictionary = {} as { [id: string]: KeyValueAnyPair }; // key is for creators, value is for hexa
      if (params.scene.texturesAnimations) {
        for (let i in params.scene.texturesAnimations) {
          for (let j in params.scene.texturesAnimations[i]) {
            if (params.scene.texturesAnimations[i][j].image && params.scene.texturesAnimations[i][j].image.indexOf('data:') === 0) {
              const base64 = params.scene.texturesAnimations[i][j].image;
              if (!this._imagesDictionary[base64])
                this._imagesDictionary[base64] = {} as KeyValueAnyPair;
              // params.scene.texturesAnimations[i][j].image = imagesDictionary[base64].key || await this.utils.getUrlFromBase64(params.scene.texturesAnimations[i][j].image);
              params.scene.texturesAnimations[i][j].image = this._imagesDictionary[base64].key || await this.utils.getUrlFromBase64(params.scene.texturesAnimations[i][j].image, 'cdn.hexa3d.io');
              this._imagesDictionary[base64].key = params.scene.texturesAnimations[i][j].image;
              // params.scene.texturesAnimations[i][j].imageH3D = imagesDictionary[base64].value || await this.utils.getUrlFromBase64(params.scene.texturesAnimations[i][j].image, 'cdn.hexa3d.io');
              // imagesDictionary[base64].value = params.scene.texturesAnimations[i][j].imageH3D;
            }
          }
        }
      }
      if (params.scene.materialManipulations) {
        for (let i in params.scene.materialManipulations) {
          if (params.scene.materialManipulations[i].envMap &&
            params.scene.materialManipulations[i].envMap.imageSrc &&
            params.scene.materialManipulations[i].envMap.imageSrc.indexOf('data:') === 0) {
            const base64 = params.scene.materialManipulations[i].envMap.imageSrc;
            if (!this._imagesDictionary[base64])
              this._imagesDictionary[base64] = {} as KeyValueAnyPair;
            // params.scene.materialManipulations[i].envMap.imageSrc = imagesDictionary[base64].key || await this.utils.getUrlFromBase64(params.scene.materialManipulations[i].envMap.imageSrc);
            params.scene.materialManipulations[i].envMap.imageSrc = this._imagesDictionary[base64].key || await this.utils.getUrlFromBase64(await this.utils.convertToJpeg(base64, 0.9), 'cdn.hexa3d.io');
            this._imagesDictionary[base64].key = params.scene.materialManipulations[i].envMap.imageSrc;
            // params.scene.materialManipulations[i].envMap.imageSrcH3D = imagesDictionary[base64].value || await this.utils.getUrlFromBase64(params.scene.materialManipulations[i].envMap.imageSrc, 'cdn.hexa3d.io');
            // imagesDictionary[base64].value = params.scene.materialManipulations[i].envMap.imageSrcH3D;
          }
        }
      }
      if (params.scene.annotations) {
        for (let i in params.scene.annotations) {
          if (params.scene.annotations[i].img && params.scene.annotations[i].img.indexOf('data:') === 0) {
            const base64 = params.scene.annotations[i].img;
            if (!this._imagesDictionary[base64])
              this._imagesDictionary[base64] = {} as KeyValueAnyPair;
            params.scene.annotations[i].img = this._imagesDictionary[base64].key || await this.utils.getUrlFromBase64(base64, 'cdn.hexa3d.io');
            this._imagesDictionary[base64].key = params.scene.annotations[i].img;
          }
          if (params.scene.annotations[i].soundEffect && params.scene.annotations[i].soundEffect.indexOf('data:') === 0) {
            const base64 = params.scene.annotations[i].soundEffect;
            if (!this._imagesDictionary[base64])
              this._imagesDictionary[base64] = {} as KeyValueAnyPair;
            params.scene.annotations[i].soundEffect = this._imagesDictionary[base64].key || await this.utils.getUrlFromBase64(base64, 'cdn.hexa3d.io');
            this._imagesDictionary[base64].key = params.scene.annotations[i].soundEffect;
          }
        }
      }
      if (params.scene.meshActions) {
        for (let i in params.scene.meshActions) {
          if (params.scene.meshActions[i].img && params.scene.meshActions[i].img.indexOf('data:') === 0) {
            const base64 = params.scene.meshActions[i].img;
            if (!this._imagesDictionary[base64])
              this._imagesDictionary[base64] = {} as KeyValueAnyPair;
            params.scene.meshActions[i].img = this._imagesDictionary[base64].key || await this.utils.getUrlFromBase64(base64, 'cdn.hexa3d.io');
            this._imagesDictionary[base64].key = params.scene.meshActions[i].img;
          }
          if (params.scene.meshActions[i].soundEffect && params.scene.meshActions[i].soundEffect.indexOf('data:') === 0) {
            const base64 = params.scene.meshActions[i].soundEffect;
            if (!this._imagesDictionary[base64])
              this._imagesDictionary[base64] = {} as KeyValueAnyPair;
            params.scene.meshActions[i].soundEffect = this._imagesDictionary[base64].key || await this.utils.getUrlFromBase64(base64, 'cdn.hexa3d.io');
            this._imagesDictionary[base64].key = params.scene.meshActions[i].soundEffect;
          }
        }
      }
      if (params.scene.meshFurOptions?.diffuseTexture) {
        if (params.scene.meshFurOptions.diffuseTexture.indexOf('data:') === 0) {
          const base64 = params.scene.meshFurOptions.diffuseTexture;
          if (!this._imagesDictionary[base64])
            this._imagesDictionary[base64] = {} as KeyValueAnyPair;
          params.scene.meshFurOptions.diffuseTexture = this._imagesDictionary[base64].key || await this.utils.getUrlFromBase64(params.scene.meshFurOptions.diffuseTexture, 'cdn.hexa3d.io');
          this._imagesDictionary[base64].key = params.scene.meshFurOptions.diffuseTexture;
        }
      }
      resolve(params);
    });
  }

  onDestroy() {
    this.onAdjustmentMessageSubs.forEach(s => s.unsubscribe());
    delete this.embedLink;
    delete this.isViewerFullyLoaded;
    delete this.exportProgress;
    delete this.currentPreset;
    delete this.onAutoAdjustSceneResolve;
    delete this.forceOffscreen;
    delete this.forceNoAdjust;
    this.savePresetFor = this.PERSONAL;
    this.onViewerFullyLoaded = [];
    this._imagesDictionary = {};
    this.modelOpacityAnimation = false;
    this.modelOpacity = 1;
  }
}
